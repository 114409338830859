import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getAssesmentData, getAssignmentData, saveAssessment, saveAssessmentMaterial} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldDateHari from "../../atoms/FromFieldDateHari";
import FromSpFieldTextAreaHari from "../../atoms/FromSpFieldTextAreaHari";


import AWS from 'aws-sdk';
import FromFieldTime from "../../atoms/FromFieldTime";
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

function IRAssessmentReport() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [aInfo, setAInfo] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [mInfo, setMInfo] = useState([]);

    const [assignedDate, setAssignedDate] = useState('');
    const [ErrAssignedDate, setErrAssignedDate] = useState('');

    const [acceptedDate, setAcceptedDate] = useState('');
    const [ErrAcceptedDate, setErrAcceptedDate] = useState('');

    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [ErrAssignmentNumber, setErrAssignmentNumber] = useState('');

    const [detailProblem, setDetailProblem] = useState('');

    const [detailProblemIdentified, setDetailProblemIdentified] = useState('');
    const [ErrDetailProblemIdentified, setErrDetailProblemIdentified] = useState('');

    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileError, setUploadFileError] = useState('');

    const [uploadScreenShot, setUploadScrrenShot] = useState('');
    const [uploadScrrenShotError, setUploadScrrenShotError] = useState('');

    const [materialUsed, setMaterialUsed] = useState('');
    const [ErrMaterialUsed, setErrMaterialUsed] = useState(false);

    const [formCategory, setFormCategory] = useState('');
    const [ErrFormCategory, setErrFormCategory] = useState(false);

    const [assignedTo, setAssignedTo] = useState('');

    const [dateOfCopletion, setDateOfCopletion] = useState('');
    const [ErrDateOfCopletion, setErrDateOfCopletion] = useState(false);

    const [timeOfCompletion, setTimeOfCompletion] = useState('');
    const [ErrTimeOfCompletion, setErrTimeOfCompletion] = useState(false);

    const [nextServiceDue, setNextServiceDue] = useState('');
    const [ErrNextServiceDue, setErrNextServiceDue] = useState(false);

    const [nextServiceDate, setNextServiceDate] = useState('');
    const [ErrNextServiceDate, setErrNextServiceDate] = useState(false);

    const [closingNote, setClosingNote] = useState('');
    const [ErrClosingNote, setErrClosingNote] = useState(false);

    const [repairCategory, setRepairCategory] = useState('');
    const [ErrRepairCategory, setErrRepairCategory] = useState(false);

    const [materials, setMaterials] = useState([{
        date: '',
        name: '',
        partNumber: '',
        make: '',
        model: '',
        serialNo: '',
        qty: '',
        cost: ''
    }]);

    const [showTextField, setShowTextField] = useState(false);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const resetUserInfo = () => {
        setDetailProblemIdentified('');
        setAssignmentNumber('');
        setClosingNote('');
        setNextServiceDue('');
        setNextServiceDate('');
        setTimeOfCompletion('');
        setDateOfCopletion('');
        setMaterialUsed('');

    };
    const params = new URLSearchParams(window.location.search);
    const encryptedAssignmentNO = params.get('assignmentNos');
    useEffect(() => {
        // Retrieve the encrypted assignment number from the URL


        // Run only if encryptedAssignmentNO exists in the query string
        if (encryptedAssignmentNO) {
            setIsUpdate(true);

            try {
                // Decrypt the assignment number using the same secret key
                const decryptedData = CryptoJS.AES.decrypt(
                    decodeURIComponent(encryptedAssignmentNO),
                    process.env.REACT_APP_ENCRYPT_SECRET_KEY
                );

                // Parse the decrypted data back to JSON
                const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedText);

                // Only call getTagInfo once the assignment number is successfully decrypted
                if (parsedData.assignment_no) {
                    setAssignmentNumber(parsedData.assignment_no);
                    getTagInfo(parsedData.assignment_no);  // Call getTagInfo once with the decrypted assignment number
                }

            } catch (error) {
                console.error("Error decrypting assignment number:", error);
                setErrMsg('Error decrypting assignment number');
            }
        } else {
            // Optional: handle if no assignment number is in the URL
            // setErrMsg('Assignment number not found in URL');
        }
    }, []);

    useEffect(() => {
    if (mInfo.length > 0) {
        const mappedMaterials = mInfo.map(material => ({
            date: material.material_date,
            name: material.material_name,
            partNumber: material.material_partNumber,
            make: material.material_make,
            model: material.material_model,
            serialNo: material.material_serialNo,
            qty: material.material_qty,
            cost: material.material_cost
        }));

        setMaterials(mappedMaterials);
    }
    }, [mInfo]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;
        let errorMessages = [];

        if (!dateOfCopletion) {
            setErrDateOfCopletion('Completion date is required');
            valid = false;
        } else {
            setErrFormCategory('');
        }
        if (!detailProblemIdentified) {
            setErrDetailProblemIdentified('Enter Detailed Problem');
            valid = false;
        } else {
            setErrDetailProblemIdentified('');
        }
        if (!closingNote) {
            setErrClosingNote('Please select closing note');
            valid = false;
        } else {
            setErrClosingNote('');
        }
        if (!timeOfCompletion) {
            setErrTimeOfCompletion('Please select yes or no');
            valid = false;
        } else {
            setErrTimeOfCompletion('');
        }
        if (!nextServiceDue) {
            setErrNextServiceDue('Please select yes or no');
            valid = false;
        } else {
            setErrNextServiceDue('');
        }
        if (nextServiceDue === "Yes") {
            if (!nextServiceDate) {
                setErrNextServiceDate('Next Service Date is required');
                valid = false;
            }
        }

        if (!valid) {
            console.log("Form submission errors:", errorMessages);
            alert(`Error: Please fix the following issues:\n\n${errorMessages.join('\n')}`);
        } else {
            saveData();
            console.log("Form submitted successfully");
        }
    };

    const handleUploadFile = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadFileError(errorMsg);
            setUploadFile([]);
        } else {
            setUploadFileError('');
            setUploadFile(validFiles);
        }
    };
    const handleUploadScreenshot = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadScrrenShotError(errorMsg);
            setUploadScrrenShot([]);
        } else {
            setUploadScrrenShotError('');
            setUploadScrrenShot(validFiles);
        }
    };
    const saveData = () => {
        try {


            const file = uploadFile[0];
            let img_name = '';
            if (file) {
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(file, img_name);

            }
            const screenshot = uploadScreenShot[0];
            let screenshot_name = '';
            if (file) {
                const fileName = screenshot.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let screenshot_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(screenshot, screenshot_name);

            }

            var obj = {

                'assignment_no': assignmentNumber,
                //'problem_desc': detailProblem,
                'detail_problem_identified': detailProblemIdentified,
                'upload_files': img_name,
                'upload_scrrenshots': screenshot_name,
                'materialUsed': materialUsed,
                'dateOfCopletion': dateOfCopletion,
                'timeOfCompletion': timeOfCompletion,
                'nextServiceDue': nextServiceDue,
                'nextServiceDate': nextServiceDate,
                'handleClosingNote': closingNote,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveAssessment(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            var obj1 = {

                'assignment_no': assignmentNumber,
                'materials': materials.map(material => ({
                    'material_date': material.date,
                    'material_name': material.name,
                    'material_partNumber': material.partNumber,
                    'material_make': material.make,
                    'material_model': material.model,
                    'material_serialNo': material.serialNo,
                    'material_qty': material.qty,
                    'material_cost': material.cost
                })),
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};

            if(materialUsed == "Yes"){
                saveAssessmentMaterial(oData1).then(res => {
                    if (res.data.errors && res.data.errors.errno > 0) {
                        setErrMsg(res.data.message);
                        setSuccess(false);
                    } else {
                        setSuccess(res.data.message);
                        resetUserInfo();
                    }
                });
            }

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const uploadFileServer = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };

    const handleFormCategory = (e) => {
        setFormCategory(e.target.value);
    }

    const handleClosingNote = (e) => {
        setClosingNote(e.target.value);
    }

    // Function to fetch assignment data using decrypted assignment number
    const getTagInfo = function (decryptedAssignmentNo) { //alert(assignmentNumber);
        // Use either the entered assignment number or the decrypted one
        const obj = assignmentNumber ? { 'assignment_no': assignmentNumber } : { 'assignment_no': decryptedAssignmentNo };

        // Encrypt the param data with the assignment number and authToken
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };
        // Fetch Assignment Data
        getAssignmentData(oData).then(function (res) {
            console.log(res.data);

            if (res?.data?.data?.length > 0) {
                setErrMsg('');  // Clear any error messages

                const firstItem = res.data.data[0];
                setAssignmentNumber(firstItem.assignment_no);
                setAssignedTo(firstItem.endUser_name);
                setAssignedDate(new Date(firstItem.assignedDate));
                setAcceptedDate(new Date(firstItem.assignment_accepted_date));
                setFormCategory(firstItem.cat_ir);
                setRepairCategory(firstItem.under_warrenty);
                setDetailProblem(firstItem.problem_desc);
                setAInfo(res.data.data);
            } else {
                console.error("Assignment data not found or is empty");
                setErrMsg('Assignment not found');
                setAInfo([]);  // Reset aInfo to an empty array if no data is found
            }
        }).catch((error) => {
            console.error("Error fetching assignment data:", error);
            setErrMsg('Failed to fetch assignment data');
            setAInfo([]);  // Handle errors by clearing aInfo
        });
        // Fetch Assessment Data
        getAssesmentData(oData).then(function (res) {
                console.log("Assessment Data Response:", res);

                if (res?.data?.data?.assessment_data) {
                    setErrMsg('');  // Clear any error messages
                    setMInfo(res.data.data.list_material_used);
                    const firstItem = res.data.data.assessment_data;
                    setDetailProblemIdentified(firstItem.detail_problem_identified);
                    setMaterialUsed(firstItem.materialUsed);
                    setDateOfCopletion(new Date(firstItem.dateOfCopletion));

                    if (firstItem.dateOfCopletion && firstItem.timeOfCompletion) {
                        const dateOfCompletion = new Date(firstItem.dateOfCopletion);
                        const [hours, minutes, seconds] = firstItem.timeOfCompletion.split(':');
                        dateOfCompletion.setHours(hours, minutes, seconds);
                        setTimeOfCompletion(dateOfCompletion);
                    } else {
                        setTimeOfCompletion(null);  // Handle null cases
                    }

                    setNextServiceDue(firstItem.nextServiceDue);
                    setNextServiceDate(new Date(firstItem.nextServiceDate));
                    setClosingNote(firstItem.handleClosingNote);
                } else {
                    console.error("Assessment data not found or is null");
                    //setErrMsg('Assessments data not found');
                }
            })
            .catch((error) => {
                console.error("Error fetching assessment data:", error);
                setErrMsg('Failed to fetch assessment data');
            });

            
    };

    const handleMaterial = (e) => {
        const { value } = e?.target || {};  // Ensure target exists
        if (value) {
            setMaterialUsed(value);
        }
    };

    const handleServices = (event) => {
        const value = event.target.value;
        setNextServiceDue(event.target.value);
        setShowTextField(value === "Yes");
    }

    const addRow = () => {
        setMaterials([...materials, {
            date: '',
            name: '',
            partNumber: '',
            make: '',
            model: '',
            serialNo: '',
            qty: '',
            cost: ''
        }]);
    };

    const removeRow = () => {
        if (materials.length > 1) {
            setMaterials(materials.slice(0, -1));
        }
    };

    const handleSearchInputChange = (event) => {
        const { name, value } = event?.target || {};
        if (!name) return;
        setAssignmentNumber(value);
    };
    const handleInputChange = (index, event) => {
        const { name, value } = event?.target || {};  // Destructure name and value from event.target
        if (!name) return;  // Prevent further execution if name is undefined


        const updatedMaterials = materials.map((material, i) => (
            i === index ? { ...material, [name]: value } : material
        ));
        setMaterials(updatedMaterials);
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>{isUpdate ? 'Update IR Assessment Report' : 'IR Assessment Report'}</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>


            <div className='row '>
                <div className='col-12'>&nbsp;</div>

                {(encryptedAssignmentNO)?(""): (<div className='row'>
                    <div className='col'><h5 className='align-right'>Assignment No </h5></div>
                    <div className='col'>
                        <input name="assignmentNumber"
                               onChange={handleSearchInputChange}
                            value={assignmentNumber}  // Set the value from state
                            type="text"
                            className="form-control"
                            placeholder='Enter the asset TAG'
                        />
                        <p className='invalid-feedback'>{ErrAssignmentNumber}</p>
                    </div>
                    <div className='col'>
                        <button onClick={() => getTagInfo(assignmentNumber)} className='btn btn-info btn-sm'>
                            <i className="bi bi-search"></i> Search
                        </button>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={'/ir-assessment-report'}>
                        <button className='btn btn-info btn-sm'>Reset</button>
                    </a>&nbsp;</div>
                </div>)}

            </div>
            <div className='row'></div>

            <div className="row">

                <form onSubmit={handleSubmit}>

                    <div className="row mt-2">

                        <div className="col-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <label> FORM Category </label>
                                    <span className="star error">  </span>
                                </div>
                                <div className="col-md-6">
                                    <select disabled onChange={handleFormCategory} name="formCategory"
                                            className="form-select form-select-sm">
                                        <option value="">Select Form Category</option>
                                        <option value="IRAI"
                                                selected={formCategory === "IRAI" ? "selected" : ''}>IT Assets
                                        </option>
                                        <option value="IRAO"
                                                selected={formCategory === "IRAO" ? "selected" : ''}>Other Asset
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrFormCategory} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className='col-md-6'>
                            <FromFieldDate
                                readOnly={true}
                                label="Assigned Date" star="*" placeholder="YYYY-MM-DD"
                                inputValue={assignedDate}
                                setInputValue={setAssignedDate}
                                setErrorMsg={ErrAssignedDate}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly={true}
                                star="*"
                                label="Assignment Number"
                                inputValue={assignmentNumber}
                                setInputValue={setAssignmentNumber}
                                setErrorMsg={setErrAssignmentNumber}
                                errorMsg={ErrAssignmentNumber}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className='col-md-6'>
                            <FromFieldDate
                                readOnly={true}
                                label="Assignment Accepted Date" star="*" placeholder="YYYY-MM-DD"
                                inputValue={acceptedDate}
                                setInputValue={setAcceptedDate}
                                setErrorMsg={ErrAcceptedDate}
                            />
                        </div>
                        <div className='col-md-6'>
                            <FromFieldText
                                readOnly={true}
                                star="*"
                                label="Assigned To"
                                placeholder="Assigned To User Name"
                                inputValue={assignedTo}
                                setInputValue={setAssignedTo}
                            />
                        </div>
                    </div>

                    {/*<div className="row mt-2">

                        <div className="col-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <label> Repair Category </label>
                                    <span className="star error">  </span>
                                </div>
                                <div className="col-md-6">
                                    <select disabled onChange={handleFormCategory} name="alternative_verification"
                                            className="form-select form-select-sm">
                                        <option value="">Select Form repair Category</option>
                                        <option value="AMC"
                                                selected={repairCategory === "AMC" ? "selected" : ''}>AMC
                                        </option>
                                        <option value="warranty"
                                                selected={repairCategory === "Warranty" ? "selected" : ''}>warranty
                                        </option>
                                        <option value="Insurance"
                                                selected={repairCategory === "Insurance" ? "selected" : ''}>Insurance
                                        </option>
                                        <option value="Paid service"
                                                selected={repairCategory === "Paid service" ? "selected" : ''}>Paid
                                            service
                                        </option>
                                        <option value="Others"
                                                selected={repairCategory === "Others" ? "selected" : ''}>Others
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrRepairCategory} </span>
                                </div>
                            </div>
                        </div>

                    </div>*/}

                    <div className="row mt-3">
                        <h6 className="text-center">List of Equipment</h6>
                        <table className="table table-bordered">
                            <thead className="text-center">
                            <tr>
                                <th>Sl #</th>
                                <th>TAG No</th>
                                <th>TAG Name</th>
                                <th>Item Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {aInfo && aInfo.length > 0 ? (
                                aInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {/* Serial number (starts from 1) */}
                                        <td>{item.tag}</td>
                                        {/* Equipment tag */}
                                        <td>{item.tag_name}</td>
                                        {/* Equipment tag name */}
                                        <td>{item.equipment_desc}</td>
                                        {/* Equipment description */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No equipment data available</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Brief description of the problem reported <span className="error">*</span></label>
                        </div>
                        <div className='col-md-6'>
                            <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                     readOnly={true}
                                                     inputValue={detailProblem}
                                                     setInputValue={setDetailProblem}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Brief description of the actual problem identified<span
                                className="error">*</span></label>
                        </div>
                        <div className='col-md-6'>
                            <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                     inputValue={detailProblemIdentified}
                                                     setInputValue={setDetailProblemIdentified}
                                                     setErrorMsg={ErrDetailProblemIdentified}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <label>Upload Files of the Problem (if applicable)</label>
                                <br/>
                                <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                    Maximum
                                    size: 5 MB. Maximum 5 files allowed at
                                    once. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                <input type="file" name="uploadFile" multiple onChange={handleUploadFile}/>
                                <span className="invalid-feedback">{uploadFileError}</span>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12 mt-3">
                                <label>Upload Screenshots of the Problem (if
                                    applicable) &nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <br/>
                                <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                    Maximum
                                    size: 5 MB. Maximum 5 files allowed
                                    once.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                <input type="file" name='uploadScreenshot' multiple
                                       onChange={handleUploadScreenshot}/>
                                <span className="invalid-feedback"> {uploadScrrenShotError} </span>
                            </div>

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label> Any Materials Used <span className="error">*</span></label>
                            <span className="star error">  </span>
                        </div>
                        <div className="col-md-3">
                            <select onChange={handleMaterial} name="materialUsed"
                                    className="form-select form-select-sm" value={materialUsed}>
                                <option value="">Select Material Used or not</option>
                                <option value="Yes"
                                        selected={materialUsed === "Yes" ? "selected" : ''}>Yes
                                </option>
                                <option value="No"
                                        selected={materialUsed === "No" ? "selected" : ''}>No
                                </option>
                            </select>
                            <span className="invalid-feedback">{ErrMaterialUsed}</span>
                        </div>
                    </div>

                    {/* Conditionally render the table if materialUsed is "Yes" */}
                    {materialUsed === "Yes" && (
                        <div className="row mt-3" style={{maxHeight: '350px', overflowY: 'auto'}}>
                            <h6 className="text-center">List of Material Used</h6>
                            <table className="table table-bordered">
                                <thead className="text-center">
                                <tr>
                                    <th>Sl #</th>
                                    <th>Date</th>
                                    <th>Material Name</th>
                                    <th>Part Number</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Serial No</th>
                                    <th>Qty</th>
                                    <th>Cost</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">{console.log(materials)}
                                {materials.map((material, index) => (

                                    <tr key={index} >
                                        <td>{index + 1}</td>
                                        <td><input type="date" name="date" value={material.date}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="text" name="name" value={material.name}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="text" name="partNumber" value={material.partNumber}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="text" name="make" value={material.make}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="text" name="model" value={material.model}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="text" name="serialNo" value={material.serialNo}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="number" name="qty" value={material.qty}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                        <td><input type="number" name="cost" value={material.cost}
                                                   onChange={(e) => handleInputChange(index, e)}/></td>
                                    </tr>
                                ))}
                                {/* Calculate and display totals */}
                                <tr>
                                    <td colSpan="7" className="text-right"><strong>Total</strong></td>
                                    <td>
                                        <strong>{materials.reduce((acc, material) => acc + Number(material.qty || 0), 0)}</strong>
                                    </td>
                                    <td>
                                        <strong>{materials.reduce((acc, material) => acc + Number(material.cost || 0), 0).toFixed(2)}</strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {/* Add/Remove Row Buttons */}
                            <div className="text-center mt-2">
                                <div className="text-center mt-2">
                                    <button type="button" className="btn btn-primary btn-sm mx-2" onClick={addRow}>Add
                                        Row
                                    </button>
                                    <button type="button" className="btn btn-danger btn-sm mx-2"
                                            onClick={removeRow}>Remove Row
                                    </button>
                                </div>

                            </div>
                        </div>

                    )}


                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Date of Completion<span className="error">*</span></label>
                        </div>
                        <div className='col-md-6'>
                            <FromFieldDateHari
                                label="Assignment Accepted Date" star="*" placeholder="YYYY-MM-DD"
                                inputValue={dateOfCopletion}
                                setInputValue={setDateOfCopletion}
                                setErrorMsg={ErrDateOfCopletion}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Time of Completions<span className="error">*</span></label>
                        </div>
                        <div className='col-md-6'>
                            <FromFieldTime
                                label="Assignment Accepted Date" star="*" placeholder="HH:mm"
                                inputValue={timeOfCompletion}
                                setInputValue={setTimeOfCompletion}
                                setErrorMsg={ErrTimeOfCompletion}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>Next Service Due (if Any)<span
                                className="error">*</span></label>
                            <span className="star error"></span>
                        </div>
                        <div className="col-md-3">
                            <select onChange={handleServices} name="nextServiceDue"
                                    className="form-select form-select-sm">
                                <option value="">Select Service Due</option>
                                <option value="Yes" selected={nextServiceDue === "Yes"}>Yes</option>
                                <option value="No" selected={nextServiceDue === "No"}>No</option>
                            </select>
                            <span className="invalid-feedback">{ErrNextServiceDue}</span>
                        </div>
                    </div>

                    {showTextField && (
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label>If Yes, select the date<span className="error">*</span></label>
                            </div>
                            <div className="col-md-6">
                                <FromFieldDateHari
                                    maxLength={2000}
                                    numRows={5}
                                    className="form-control"
                                    inputValue={nextServiceDate}
                                    setInputValue={setNextServiceDate}
                                    onChange={(e) => setNextServiceDate(e.target.value)}
                                />
                                <span className="invalid-feedback">{ErrNextServiceDate}</span>
                            </div>
                        </div>
                    )}

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label> Closing Note <span
                                className="error">*</span></label>
                            <span className="star error">  </span>
                        </div>
                        <div className="col-md-3">
                            <select onChange={handleClosingNote} name="alternative_verification"
                                    className="form-select form-select-sm">
                                <option value="">Select Closing Note</option>
                                <option value="5"
                                        selected={closingNote === "4" ? "selected" : ''}>Repair Completed - Asset Working
                                </option>
                                <option value="6"
                                        selected={closingNote === "5" ? "selected" : ''}>Recommended for Disposal
                                </option>
                                <option value="7"
                                        selected={closingNote === "6" ? "selected" : ''}>Repair Not Completed - Send to external
                                </option>
                            </select>
                            <span className="invalid-feedback">{ErrClosingNote} </span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">

                        </div>

                    </div>

                    <div className="row mt-4 mb-2">

                        {(isUpdate)?(<div className="col-md-12 d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary btn-sm">Update  </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm ms-3"  // Left margin for spacing
                                onClick={resetUserInfo}
                            >Reset</button>
                        </div>): (<div className="col-md-12 d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary btn-sm">Submit Report</button>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm ms-3"  // Left margin for spacing
                                onClick={resetUserInfo}
                            >
                                Reset
                            </button>
                        </div>)}


                    </div>

                </form>
            </div>
        </div>
    );
}

export default IRAssessmentReport;