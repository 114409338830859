import React, { useEffect, useState } from 'react';
import * as CryptoJS from "crypto-js";
import {getGatePassData, saveReceiveAsset, saveReceiveAssetRemark} from "../../services/asset.service";
import { useLocation } from "react-router-dom";
import FromFieldText from "../../atoms/FromFieldText";
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
function ReceiveAsset() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // start with loading false
    const [gatePassNo, setGatePassNo] = useState('');
    const [assetInfo, setAssetInfo] = useState({});
    const [receiptType, setReceiptType] = useState('');
    const [items, setItems] = useState([]); // start with an empty items array
    const [descriptions, setDescriptions] = useState({});
    const [ErrReceiptType, setErrReceiptType] = useState('');
    const [ErrDescription, setErrDescription] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const [assetCondition, setAssetCondition] = useState('');
    const [ErrAssetCondition, setErrAssetCondition] = useState('')

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const GatePassNo = query.get('gatePassNo');

    const getTagInfo = () => {
        const authToken = sessionStorage.getItem('LoggedInToken');
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'gatePassNo': gatePassNo},
            'authToken': authToken,
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        setLoading(true);
        getGatePassData({'token': encryptedData})
            .then((res) => {
                if (res?.data?.data?.length) {
                    const data = res.data.data;
                    //alert(res.data.data.tag);
                    setAssetInfo(data[0]);
                    setItems(data); // populate items only after successful fetch
                    setErrMsg('');
                } else {
                    setItems([]); // clear items if no data is found
                    setErrMsg('Gate Pass No not found');
                }
            })
            .catch((error) => {
                console.error('Error fetching gate pass data:', error);
                setErrMsg('Error fetching gate pass data');
                setItems([]); // clear items on error
            })
            .finally(() => setLoading(false));
    };

    const handleReceiptTypeChange = (event) => {
        const selectedType = event.target.value;
        setReceiptType(selectedType);
        setErrReceiptType(selectedType ? '' : 'Please select a receipt type');
    };

    const [tagData, setTAGData] = useState([]);

    // Initialize tagData whenever items change
    useEffect(() => {
        const newTagData = [];
        items.forEach((item, index) => {
            newTagData[index] = item.tag;
        });
        setTAGData(newTagData);
    }, [items]);


    const handleDescriptionChange = (index, value) => {
        setDescriptions((prev) => ({...prev, [index]: value}));
        setErrDescription((prev) => ({...prev, [index]: value ? '' : 'Please enter a description'}));
    };

    const handleCheckboxChange = (index) => {
        setSelectedItems((prev) => {
            if (prev.includes(index)) {
                return prev.filter((item) => item !== index);
            } else {
                return [...prev, index];
            }
        });
    };
    const handleAssetCondition = (event) => {
        setAssetCondition(event.target.value);  // Update the state with the selected value
    };

    const saveData = () => {
        try {

            var obj = {
                'gate_pass_no': gatePassNo,
                'receipt_type': receiptType,
                'asset_condition': assetCondition,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            };

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveReceiveAsset(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    //resetUserInfo();
                }
            });

            const obj1 = {
                'gate_pass_no': gatePassNo,
                'tag': tagData, // Using updated tagData
                'remark': descriptions,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            };

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};
            saveReceiveAssetRemark(oData1).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    //resetUserInfo();
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (!TEXT_REGEX.test(receiptType)) {
            setErrReceiptType('Receipt Type is required');
            valid_Name = false;
        } else {
            setErrReceiptType('');
        }
        if (!TEXT_REGEX.test(assetCondition)) {
            setErrAssetCondition('Select asset condition');
            valid_Name = false;
        } else {
            setErrAssetCondition('');
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            saveData();
            //handleNextClick();
        }
    }

    return (
        <div className='container'>
            <h5> Receive Asset </h5>

            {success && (
                <div className="alert alert-success">
                    <i className="bi bi-check-circle-fill"></i> {success}
                </div>
            )}
            {errMsg && (
                <div className="alert alert-danger">
                    <i className="bi bi-exclamation-triangle-fill"></i> {errMsg}
                </div>
            )}

            <div className="row mt-4">
                <div className="col"><h6>Enter Gate Pass No</h6></div>
                <div className="col">
                    <input
                        value={gatePassNo}
                        onChange={(e) => setGatePassNo(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder='Enter the gate pass no'
                    />
                </div>
                <div className="col">
                    <button onClick={getTagInfo} className="btn btn-info btn-sm">
                        <i className="bi bi-search"></i> Search
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href='/print-gate-pass'>
                        <button className='btn btn-info btn-sm'>Reset</button>
                    </a>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6">
                    <FromFieldText
                        label="Gate Pass No"
                        inputValue={assetInfo.gatePassNo || ''}
                        readOnly
                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6">

                    <div className="row ">
                        <div className="col-6">
                            <label>Full / Partial Receipt <span className="star error"> * </span></label>
                        </div>
                        <div className="col-6">
                            <select onChange={handleReceiptTypeChange}
                                    className="form-select form-select-sm mb-3">
                                <option value="">Select One</option>
                                <option value="Full"
                                        selected={receiptType === "Full" ? "selected" : ''}>Full
                                </option>
                                <option value="Partial"
                                        selected={receiptType === "Partial" ? "selected" : ''}>Partial
                                </option>
                            </select>
                            <span className="invalid-feedback">{ErrReceiptType}</span>
                        </div>
                    </div>
                </div>
            </div>

            {(receiptType === "Full" || receiptType === "Partial") && (
                <div className="row mt-4">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                            <tr>
                                {receiptType === "Partial" && <th>Select</th>}
                                <th>TAG No</th>
                                <th>TAG Name</th>
                                <th>Descriptions</th>
                                <th>Qty</th>
                                <th>Currency Type</th>
                                <th>Value</th>
                                <th>Invoice No</th>
                                <th>Remarks</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.length > 0 ? items.map((item, index) => (
                                <tr key={index}>
                                    {receiptType === "Partial" && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(index)}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        </td>
                                    )}
                                    <td>{item.tag}</td>
                                    <td>{item.tag_name}</td>
                                    <td>{item.asset_description}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.invoice_currency}</td>
                                    <td>{item.value_of_asset}</td>
                                    <td>{item.invoice_no}</td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Remark"
                                            value={descriptions[index] || ''}
                                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                        />
                                        {ErrDescription[index] &&
                                            <span className="error-message">{ErrDescription[index]}</span>}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={receiptType === "Partial" ? 9 : 8}>No Data</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <div className="row mt-3">
                <div className="col-6">

                    <div className="row ">
                        <div className="col-6">
                            <label>Received asset in Working Condition <span className="star error"> * </span></label>
                        </div>
                        <div className="col-6">
                            <select onChange={handleAssetCondition}
                                    className="form-select form-select-sm mb-3">
                                <option value="">Select One</option>
                                <option value="Yes"
                                        selected={assetCondition === "Yes" ? "selected" : ''}>Yes
                                </option>
                                <option value="No"
                                        selected={assetCondition === "No" ? "selected" : ''}>No
                                </option>
                            </select>
                            <span className="invalid-feedback">{ErrAssetCondition}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-6'>
                    <form name="" onSubmit={handleSubmit}>
                        <button className="btn btn-primary btn-md float-end"> Save
                        </button>
                    </form>
                </div>
                <div className="col-md-6">

                </div>
            </div>

        </div>
    );
}

export default ReceiveAsset;
