import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import {getAuditLogData} from '../../../services/asset.service';

import * as CryptoJS from "crypto-js";

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);
function ViewAuditLog() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {"field": "company_name","hide": false, "headerName": "Company Name", "width": 250},

        {"field": "action", "hide": false, "headerName": "Action", "width": 150},

        {"field": "resource", "hide": false, "headerName": "Resource", "width": 150},

        {"field": "details", "hide": false, "headerName": "Details", "width": 450},

        {"field": "created_by_name", "hide": false, "headerName": "Created By Name", "width": 150},

    ];


    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        // Set loading to true before making the request
        setLoading(true);

        // Fetch data
        getAuditLogData(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                // Set loading to false after the request is completed (success or failure)
                setLoading(false);
            });
    }, []);

    return (
        <div className='container'>
            <div className='col-md-6'><h5>Audit Log Detail List</h5></div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}
        </div>
    )
}

export default ViewAuditLog
