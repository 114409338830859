import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getFilterPVData, getPhysicalVerifiedAssetsCSV, getPhyVeriMaster} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Parser } from "json2csv";

function getFormattedDate(dateString) {
    if (dateString) {
        const dte = new Date(dateString);
        const year = dte.getFullYear();
        const month = (dte.getMonth() + 1).toString().padStart(2, '0');
        const day = dte.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return null;
}

const columns = [
    {"field": "tag", "editable": false, "hide": false, "headerName": "TAG Name", "width": 250},
    {"field": "parent_tag", "editable": false, "hide": false, "headerName": "Parent TAG", "width": 250},
    {
        "field": "phy_varify_table_name", "editable": false, "hide": false, "headerName": "PV table name", "width": 220,
        renderCell: (params) => (
            <a href={`/physical-verfication-master/view?pvid=${params.row.id}`}>{params.value}</a>
        )
    },
    {"field": "nature_of_presence", "editable": false, "hide": false, "headerName": "Nature of Presence", "width": 180},
    {"field": "value_of_asset", "editable": false, "hide": false, "headerName": "Value of Assets", "width": 180},
    {
        "field": "total_cost_without_tax",
        "editable": true,
        "hide": false,
        "headerName": "Total Cost Without Tax",
        "width": 220
    },
    {"field": "verification_mode", "editable": false, "hide": false, "headerName": "Verification Mode", "width": 180},
    {"field": "name", "editable": false, "hide": false, "headerName": "User Name", "width": 180},
    {"field": "email", "editable": false, "hide": false, "headerName": "User Email", "width": 180},
    {"field": "company_name", "editable": false, "hide": true, "headerName": "Company Name", "width": 350},
    {"field": "division_name", "editable": false, "hide": true, "headerName": "Division Name", "width": 350},
    {"field": "method", "editable": false, "hide": false, "headerName": "Method", "width": 180},
    {
        "field": "last_verification_date",
        "editable": false, "hide": false,
        "headerName": "Last Verification Date",
        "width": 180,
        renderCell: (params) => (
            <p>{getFormattedDate(params.row.last_verification_date)}</p>
        )
    },
    {
        "field": "last_verification_remarks",
        "editable": false, "hide": false, "headerName": "Verification Remark", "width": 250
    },
    {
        "field": "change_user_location",
        "editable": false, "hide": false, "headerName": "Change User Location", "width": 180
    },
    {"field": "reason_for_change", "editable": false, "hide": false, "headerName": "Reason For Change", "width": 180},
    {
        "field": "status_of_verification",
        "editable": false, "hide": false, "headerName": "Status of Verification", "width": 180
    },
    {"field": "employee_id", "editable": false, "hide": false, "headerName": "Employee Id", "width": 180},
    {"field": "employee_name", "editable": false, "hide": false, "headerName": "Employee Name", "width": 180},
    {"field": "floor", "editable": false, "hide": false, "headerName": "Floor", "width": 180},
    {"field": "include_child", "editable": false, "hide": false, "headerName": "Include Child", "width": 180},
    {"field": "premises", "editable": false, "hide": false, "headerName": "Premises", "width": 180},
    {"field": "site_id", "editable": false, "hide": false, "headerName": "Site Id", "width": 130},
    {"field": "user_department", "editable": false, "hide": false, "headerName": "User Department", "width": 180},
    {"field": "user_type", "editable": false, "hide": false, "headerName": "User Type", "width": 180},
    {
        "field": "created_at", "editable": false, "hide": false, "headerName": "Verification Date", "width": 180,
        renderCell: (params) => (
            <p>{getFormattedDate(params.row.created_at)}</p>
        )
    }
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function PhysicalVerificationList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);

    const [itemsCSV, setItemsCSV] = useState([]);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [pvMaster, setPvMaster] = useState([]);
    const [pvMasterTable, setPvMasterTable] = useState('');
    const [errPvMasterTable, setErrPvMasterTable] = useState(false);
    const [typeOfAssets, setTypeOfAssets] = useState('');
    const [errTypeOfAssets, setErrTypeOfAssets] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchData = (params = {}) => {
        setLoading(true);
        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {
                'pv_table_no': params.pvMasterTable || pvMasterTable,
                'typeOfAssets': params.typeOfAssets || typeOfAssets,
                'offset': offset,
                'pageSize': pageSize
            },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        getFilterPVData(requestData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                    setTotalItems(res.data.total || 0);
                } else {
                    setItems([]); // Clear items if no data is received
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setErrMsg(error.message || 'Error fetching data');
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'offset': offset, 'pageSize': pageSize},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = {'token': encryptedData};

        getPhyVeriMaster(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setPvMaster(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setErrMsg(error.message || 'Error fetching data');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handlePvMaster = (e) => {
        setPvMasterTable(e.target.value);
    };

    const getPvInfo = () => {
        if (pvMasterTable || typeOfAssets) {
            setErrPvMasterTable('');
            setErrTypeOfAssets('');
            setItems([]);
            setPage(1);  // Reset to the first page when filtering
            fetchData({pvMasterTable, typeOfAssets});
        } else {
            setErrPvMasterTable('Please select physical master table OR select type of asset');
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
        fetchData();  // Fetch data with new page size
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchData();  // Fetch data with new page
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    const fetchAssetsCSV = useCallback(async () => {
        setExportLoading(true);
        try {
            const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify({authToken: sessionStorage.getItem('LoggedInToken')}),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            const requestData = {token: encryptedData};
            const resCSV = await getPhysicalVerifiedAssetsCSV(requestData);

            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setExportLoading(false);
        }
    }, []);

    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
    };

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag || '',
                parent_tag: item.parent_tag || '',
                phy_varify_table_name: item.phy_varify_table_name || '',
                value_of_asset: item.value_of_asset || '',
                asset_name: item.asset_name || '',
                total_cost_without_tax: item.total_cost_without_tax || '',
                nature_of_presence: item.nature_of_presence || '',
                verification_mode: item.verification_mode || '',
                email: item.email || '',
                name: item.name || '',
                company_name: item.company_name || '',
                division_name: item.division_name || '',
                method: item.method || '',
                last_verification_date: item.last_verification_date ? getFormattedDate(item.last_verification_date) : '',
                last_verification_remarks: item.last_verification_remarks || '',
                change_user_location: item.change_user_location || '',
                reason_for_change: item.reason_for_change || '',
                status_of_verification: item.status_of_verification || '',
                employee_id: item.employee_id || '',
                employee_name: item.employee_name || '',
                floor: item.floor || '',
                include_child: item.include_child || '',
                premises: item.premises || '',
                site_id: item.site_id || '',
                user_department: item.user_department || '',
                user_type: item.user_type || '',
                created_at: item.created_at ? getFormattedDate(item.created_at) : '',
            }));

            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Verified_Assets_Reports.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Physical Verification Report</h5></div>
            </div>
            <div className="row">
                <div className="row form-fields">
                    <div className="label col-2"><label> Physical Master Table </label>
                        <span className="star error"> * </span>
                    </div>
                    <div className="col-3">
                        <select onChange={handlePvMaster} className="form-select form-select-sm">
                            <option value="">Select PV Master Table</option>
                            {pvMaster && pvMaster.length > 0 ? (
                                pvMaster.map((option) => (
                                    <option value={option.id} key={option.id}>
                                        {option.phy_varify_table_name}
                                    </option>
                                ))
                            ) : (<option value="">No Data</option>)}
                        </select>
                        <span className="invalid-feedback"> {errPvMasterTable} </span>
                    </div>
                    <div className="col-4">
                        <FromFieldSelect
                            label="Type of Assets"
                            placeholder="Type of Assets"
                            selectedValue={typeOfAssets}
                            setSelectedValue={setTypeOfAssets}
                            values={["Parent", "Child"]}
                            setErrorMsg={errTypeOfAssets}
                        />
                    </div>
                    <div className="col-2">
                        <button onClick={getPvInfo} className='btn btn-primary btn-md'><i
                            className="bi bi-filter"></i> Filter
                        </button>
                    </div>
                </div>

                <div className="row form-fields mt-2 mb-2">
                    <div className="label col-2"></div>
                    <div className="col-3"></div>
                    <div className="col-4"></div>
                    <div className="col-3">
                        <button onClick={handleExportToCSV} className='btn btn-primary btn-md' disabled={exportLoading}>
                            <i className="bi bi-download"></i> {exportLoading ? 'Exporting...' : 'Export All Data to CSV'}
                        </button>
                    </div>
                </div>

            </div>

            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        //Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        label="Rows per page"
                                    >
                                        {[10, 25, 50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>

        </div>
    )
}

export default PhysicalVerificationList;
