import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { getDisposaleList } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";


function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function DisposalListing() {

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  
  
const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},
    {"field":"tag","hide":false ,"headerName":"TAG","width":250},
    {"field":"cat_of_flag","hide":false ,"headerName":"Cat of Flag","width":280},
    {"field":"remarks","hideable":true,"hide":true ,"headerName":"Remarks","width":250},
    {"field":"purpose_of_grouping","hide":false ,"headerName":"Purpose","width":230},
    {"field":"existing_ownership","hide":false ,"headerName":"Existing Ownership","width":230},
    {"field":"new_ownership","hide":false ,"headerName":"New Ownership","width":230},
    
    {"field":"created_at","hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
];
{/*{"field":"asset_image","hide":false ,"headerName":"Action","width":200,
    renderCell: (params) => (
      <div><a href="#" onClick={() => handleAccept(params.row.id) } >Accept</a> / <a href="#/" onClick={() => handleReject(params.row.id) } >Reject</a></div>
    ) },*/}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    getDisposaleList(oData).then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })
  },[])
  
  // readAssets();
  return (
    <div className='container'><h5> Disposal Listing </h5>
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      

      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        //height={500}
        //autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}

export default DisposalListing
