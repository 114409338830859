import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import FromFieldText from "../../atoms/FromFieldText";
import * as CryptoJS from "crypto-js";
import { getGatePassData, getGatePassList } from "../../services/asset.service";
import { useLocation } from "react-router-dom";

function getFormatedDate(dateString) {
    if (dateString != null) {
        const dte = new Date(dateString);
        const year = dte.getFullYear();
        const month = String(dte.getMonth() + 1).padStart(2, '0');
        const day = String(dte.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        return null;
    }
}

function PrintGatePass() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [gatePassNo, setGatePassNo] = useState('');
    const [assetInfo, setAssetInfo] = useState({});
    const [items, setItems] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const GatePassNo = query.get('gatePassNo');

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
    };

    const columns = [
        { field: "id", hideable: true, hide: true, headerName: "Sl No", width: 50 },
        { field: "tag", hide: false, editable: false, headerName: "TAG", width: 250 },
        { field: "tag_name", hide: false, editable: false, headerName: "TAG Name", width: 250 },
        {
            field: "gatePassNo",
            hideable: true,
            hide: false,
            editable: false,
            headerName: "Gate Pass No",
            width: 250,
            renderCell: (params) => {
                const EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'gatePassNo': params.row.gatePassNo,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/gate-pass-print-page?gatePassNo=${EncryptID}`}>{params.row.gatePassNo}</a>);
            }
        },
        {
            field: "gatePassDate",
            editable: false,
            hide: false,
            headerName: "Gate Pass Date",
            width: 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.gatePassDate)}</p>
            )
        },
    ];

    useEffect(() => {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData = { 'token': EncryptData };

        setLoading(true);

        getGatePassList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching gate pass list:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getTagInfo = () => {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'gatePassNo': gatePassNo },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData = { 'token': EncryptData };

        getGatePassData(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    const Info = res.data.data;
                    setAssetInfo(Info[0]);
                    setItems(Info);  // Set the DataGrid to display only the search result
                    setErrMsg('');   // Clear any previous error message
                } else {
                    setItems([]);    // Clear the DataGrid if no data found
                    setErrMsg('Gate Pass No not found'); // Set error message
                }
            })
            .catch((error) => {
                console.error('Error fetching gate pass data:', error);
                setErrMsg('Error fetching gate pass data');
            });
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <div className='container'>
            <h5> Print Gate Pass </h5>

            <div className="row">
                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : errMsg ? (
                    <div className="alert alert-danger" role="alert">
                        <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                    </div>
                ) : null}
            </div>

            <div className='row mt-4'>
                <div className='col-12'>&nbsp;</div>
                <div className='row'>
                    <div className='col'><h6 className='align-right'>Enter Gate Pass No </h6></div>
                    <div className='col'>
                        <input
                            value={gatePassNo}
                            onChange={(e) => setGatePassNo(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder='Enter the gate pass no'
                        />
                    </div>
                    <div className='col'>
                        <button onClick={getTagInfo} className='btn btn-info btn-sm'>
                            <i className="bi bi-search"></i> Search
                        </button>
                        &nbsp;
                        <a href={'/print-gate-pass'}>
                            <button className='btn btn-info btn-sm'>Reset</button>
                        </a>
                    </div>
                </div>
            </div>

            <div className='row mt-3'>
                <div className="col-6">
                    <FromFieldText
                        label="Gate Pass No"
                        inputValue={assetInfo.gatePassNo || ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>

            <DataGrid
                rows={items}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={50}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                autoHeight
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}

export default PrintGatePass;
