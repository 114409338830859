import React, { useState, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import { getQuoteTagsList } from '../../../services/disposal.service';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [

    { "field": "din_no", "hide": false,  "headerName": "DIN No", "width": 220},
    { "field": "tag", "hide": false,  "headerName": "TAG", "width": 220},
    { "field": "buyer_no",  "hide": false,  "headerName": "Buyer Number", "width": 220},
    { "field": "buyer_name",  "hide": false,  "headerName": "Buyer Name", "width": 220},
    { "field": "buyer_price_quoted",  "hide": false, "headerName": "Quoted Price", "width": 220 },
    { "field": "buyer_address",  "hide": false, "headerName": "Buyer Address", "width": 220},
    { "field": "buyer_PAN",  "hide": false, "headerName": "PAN", "width": 220 },
    { "field": "buyer_GSTN",  "hide": false, "headerName": "GSTN", "width": 220 },
    { "field": "buyer_price_quoted_date",  "hide": false, "headerName": "Quote Date", "width": 220,
        renderCell: (params) => (
        <p>{ getFormatedDate(params.row.buyer_price_quoted_date)}</p>
      ) },
    { "field": "buyer_price_quoted_expiry_date",  "hide": false, "headerName": "Quote Expiry Date", "width": 220,
        renderCell: (params) => (
        <p>{ getFormatedDate(params.row.buyer_price_quoted_expiry_date)}</p>
      ) },
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Created Date","width":180,
        renderCell: (params) => (
            <p>{ getFormatedDate(params.row.created_at)}</p>
        ) },

];

function ListQuote() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const DinNo = query.get('din_no');
    useEffect(() => {
        if (DinNo !== '' && DinNo !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(DinNo.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedDinNo = decrypt_data.din_no;
            //alert(decryptedDinNo);
            // encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'din_no': decryptedDinNo},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = { 'token': EncryptData };
        setLoading(true);
        getQuoteTagsList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }, []);

    // readAssets();
    const CustomToolbar = () => (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
    return (
        <div className='container'>
            <div class="row"><div class="col">
                <h5>List of Quote Tags</h5>
            </div></div>
            <div class="row"><div className='col-10'>&nbsp;</div>
                    <div className='col-2'>
                        <a href={'/listquote'}>
                        <button className='btn btn-secondary'><i className="bi bi-arrow-left"></i> Back</button>
                    </a></div>
                    
            </div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                    onCellEditStop={onCellEditStopFn}
                />
            )}

        </div>
    )
}

export default ListQuote


