import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import {getConsumableAssets, getConsumableAssetsCSV} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import './AssetInfo.css';
import {Box, FormControl, InputLabel, MenuItem, Pagination, Select} from '@mui/material';
import {Parser} from "json2csv";
import FromFieldDate from "../../../atoms/FromFieldDate";


Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
};

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const columns = [
    {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
    {
        "field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250,
        renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'tag': params.row.tag,'back_url':'my-consumable-asset-list'
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/consumables-asset-details?tag=${EncryptID}`}>{params.value}</a>);
        }
    },
    {
        "field": "parent_tag",
        "hideable": true,
        "hide": false,
        "editable": true,
        "headerName": "Parent TAG",
        "width": 250
    },
    {"field": "grouping_name", "editable": true, "hide": false, "headerName": "Grouping Name", "width": 130},
    {
        "field": "year_of_addition",
        "hideable": true,
        "hide": false,
        "editable": true,
        "headerName": "Year of Addition",
        "width": 130
    },
    {"field": "type_of_addition", "editable": true, "hide": false, "headerName": "Type of Addition", "width": 130},
    {"field": "project_marking", "editable": true, "hide": false, "headerName": "Project Marking", "width": 130},
    {"field": "po_no", "editable": true, "hide": false, "headerName": "PO No (If Any)", "width": 130},
    {"field": "supplier_code", "editable": true, "hide": false, "headerName": "Supplier Code (if Any)", "width": 130},
    {"field": "supplier_name", "editable": true, "hide": false, "headerName": "Name of the Supplier", "width": 130},
    {"field": "invoice_no", "editable": true, "hide": false, "headerName": "Invoice No", "width": 130},
    {
        "field": "invoice_date", "editable": true, "hide": false, "headerName": "Invoice Date", "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.invoice_date)}</p>
        )
    },
    {
        "field": "accounting_voucher_no",
        "editable": true,
        "hide": false,
        "headerName": "Accounting voucher no",
        "width": 130
    },
    {
        "field": "internal_approval_no",
        "editable": true,
        "hide": false,
        "headerName": "Internal approval no",
        "width": 130
    },
    {"field": "cost_center_code", "editable": true, "hide": false, "headerName": "Cost center code", "width": 130},
    {"field": "cost_center_name", "editable": true, "hide": false, "headerName": "Cost center name", "width": 130},

    {"field": "qty", "editable": true, "hide": true, "headerName": "Qty", "width": 130},
    {
        "field": "asset_name",
        "editable": true,
        "hide": false,
        "headerName": "Name of the Asset (as per Invoice)",
        "width": 130
    },
    {
        "field": "asset_description",
        "editable": true,
        "hide": false,
        "headerName": "Detailed Description of the Asset",
        "width": 130
    },
    {
        "field": "asset_serial_no",
        "editable": true,
        "hide": false,
        "headerName": "Unique Sl No of the Asset",
        "width": 130
    },
    {"field": "manufacturer", "editable": true, "hide": false, "headerName": "Make / Manufacturer", "width": 130},
    {"field": "model_no", "editable": true, "hide": false, "headerName": "Model No", "width": 130},
    {"field": "part_no", "editable": true, "hide": false, "headerName": "Part No", "width": 130},
    {"field": "nature_of_presence", "editable": true, "hide": false, "headerName": "Nature of Presence", "width": 130},
    {"field": "nature_of_addition", "editable": true, "hide": false, "headerName": "Nature of Addition", "width": 130},
    {
        "field": "principal_asset_tag",
        "editable": true,
        "hide": false,
        "headerName": "Principal Asset to which Cost is Added",
        "width": 130
    },
    {"field": "tag_name", "editable": true, "hide": false, "headerName": "TAG Name", "width": 130},
    {"field": "tag_division", "editable": true, "hide": false, "headerName": "TAG Division", "width": 130},
    {"field": "department_name", "editable": true, "hide": false, "headerName": "Control Department Name", "width": 180},
    {"field": "unit_of_measure", "editable": true, "hide": false, "headerName": "Unit of Measure", "width": 130},
    {"field": "qty", "editable": true, "hide": true, "headerName": "Qty", "width": 130},
    
    {
        "field": "created_at", "editable": true, "hide": false, "headerName": "Date of Addition", "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport/>
    </GridToolbarContainer>
);

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

const MyConsumableAssetList = () => {
    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);

    const [searchTag, setSearchTag] = useState('');
    const [ErrSearchTag, setErrSearchTag] = useState(false);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [filterCriteria, setFilterCriteria] = useState('');
    const [ErrFilterCriteria, setErrFilterCriteria] = useState(false);

    const [filterOperator, setFilterOperator] = useState('');
    const [ErrFilterOperator, setErrFilterOperator] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState(false);

    const [endDate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState(false);
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getConsumableAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            }else{
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize]);

    const fetchAssets1 = async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize, 'searchTag': searchTag, 'filterCriteria': filterCriteria, 'filterOperator': filterOperator, 'startDate': getFormatedDate(startDate), 'endDate': getFormatedDate(endDate) },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getConsumableAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

// Call this function whenever you need to fetch assets, such as inside a useEffect hook or in response to an event


    const fetchAssetsCSV = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize); // Add pagination if needed
        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify({
                param_data: {
                    offset,
                    pageSize,
                    searchTag,
                    filterCriteria,
                    filterOperator,
                    startDate: getFormatedDate(startDate),
                    endDate: getFormatedDate(endDate),
                },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        const requestData = {token: encryptedData};

        try {
            const resCSV = await getConsumableAssetsCSV(requestData);
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setLoading(false);
        }
    }, [filterCriteria, filterOperator, searchTag, startDate, endDate, page, pageSize]);

    useEffect(() => {
        fetchAssets();
        //fetchAssets1();
    }, [fetchAssets]);

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
    };

    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag ? item.tag : '',
                parent_tag: item.parent_tag ? item.parent_tag : '',
                year_of_addition: item.year_of_addition ? item.year_of_addition : '',
                type_of_addition: item.type_of_addition ? item.type_of_addition : '',
                project_marking: item.project_marking ? item.project_marking : '',
                po_no: item.po_no ? item.po_no : '',
                supplier_code: item.supplier_code ? item.supplier_code : '',
                supplier_name: item.supplier_name ? item.supplier_name : '',
                invoice_no: item.invoice_no ? item.invoice_no : '',
                invoice_date: item.invoice_date ? getFormatedDate(item.invoice_date) : '',
                boe_no: item.boe_no ? item.boe_no : '',
                boe_date: item.boe_date ? getFormatedDate(item.boe_date) : '',
                shipping_awb_no: item.shipping_awb_no ? item.shipping_awb_no : '',
                shipping_awb_date: item.shipping_awb_date ? getFormatedDate(item.shipping_awb_date) : '',
                accounting_voucher_no: item.accounting_voucher_no ? item.accounting_voucher_no : '',
                internal_approval_no: item.internal_approval_no ? item.internal_approval_no : '',
                cost_center_code: item.cost_center_code ? item.cost_center_code : '',
                cost_center_name: item.cost_center_name ? item.cost_center_name : '',
                qty: item.qty ? item.qty : '',
                asset_name: item.asset_name ? item.asset_name : '',
                asset_description: item.asset_description ? item.asset_description : '',
                asset_serial_no: item.asset_serial_no ? item.asset_serial_no : '',
                manufacturer: item.manufacturer ? item.manufacturer : '',
                model_no: item.model_no ? item.model_no : '',
                part_no: item.part_no ? item.part_no : '',
                nature_of_presence: item.nature_of_presence ? item.nature_of_presence : '',
                nature_of_addition: item.nature_of_addition ? item.nature_of_addition : '',
                principal_asset_tag: item.principal_asset_tag ? item.principal_asset_tag : '',
                tag_name: item.tag_name ? item.tag_name : '',
                tag_division: item.tag_division ? item.tag_division : '',
                department_name: item.department_name ? item.department_name : '',
                unit_of_measure: item.unit_of_measure ? item.unit_of_measure : '',
                security_seal_date: item.security_seal_date ? getFormatedDate(item.security_seal_date) : '',
                comp_ref_no: item.comp_ref_no ? item.comp_ref_no : '',
                created_at: item.created_at ? getFormatedDate(item.created_at) : '',
                // Add more fields as needed based on your item structure
            }));

            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'My_Consumables_List.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    const handleSearch = () => {
        let isValid = true;
        if (!filterCriteria) {
            setErrFilterCriteria('Please select a filter criteria');
            isValid = false;
        } else {
            setErrFilterCriteria('');
        }

        if (filterCriteria !== 'created_at' && !filterOperator) {
            setErrFilterOperator('Please select a filter operator');
            isValid = false;
        } else {
            setErrFilterOperator('');
        }

        if (!searchTag && filterCriteria != 'created_at' ) {
            setErrSearchTag('Please enter a search text');
            isValid = false;
        }else{
            setErrSearchTag('');
        }
        if (!startDate && filterCriteria == 'created_at' ) {
            setErrStartDate('Please select start date');
            isValid = false;
        }else{
            setErrStartDate('');
        }
        if (!endDate && filterCriteria == 'created_at' ) {
            setErrEndDate('Please select end date');
            isValid = false;
        }else{
            setErrEndDate('');
        }

        if (isValid) {
            setPage(1);
            fetchAssets1();
        }

    };

    // Define options and values
    const options = [
        "TAG Number", "Year Of Addition", "Type of Addition", "PO Number", "Supplier Name", "Invoice Number", "Asset Name", "Make",
        "Model Number", "Asset Serial Number", "Part Number", "TAG Name", "Nature of Presence", "Date Range"
    ];

    const values = [
        "tag", "year_of_addition", "type_of_addition", "po_no", "supplier_name", "invoice_no", "asset_name", "manufacturer", "model_no", "asset_serial_no", "part_no", "tag_name", "nature_of_presence", "created_at"
    ];

    // Combine options and values into an array of objects
    const combinedOptions = options.map((option, index) => ({
        label: option,
        value: values[index]
    }));

    // Handle change event
    const handleSelectChange = (e) => {
        const selectedOption = combinedOptions.find(option => option.value === e.target.value);
        setFilterCriteria(selectedOption ? selectedOption.value : '');
        setErrFilterCriteria(''); // Reset error message on change
    };

    const optionsFilter = [
        "Contains", "Equals", "Not Contains", "Not Equals", "Blank", "Non Blank"
    ];

    const valuesFilter = [
        "LIKE", "=", "NOT LIKE", "!=", "IS NULL", "IS NOT NULL"
    ];

    // Combine options and values into an array of objects
    const combinedOptionsFilter = optionsFilter.map((option, index) => ({
        label: option,
        value: valuesFilter[index]
    }));

    // Handle operator change event
    const handleOperatorChange = (e) => {
        setFilterOperator(e.target.value);
        setErrFilterOperator(''); // Reset error message on change
    };
    const handelSearchText = (e) => {
        setSearchTag(e.target.value);
    };


    return (
        <div className='container'>
            <h5> My Consumables List </h5>

            <div className='filter-container' id="single-asset">

                <div className='row m-2'>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label>
                                Select Criteria <span className="star error">*</span>
                            </label>
                            <select
                                className="form-control"
                                value={filterCriteria}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>Select Filter Criteria</option>
                                {combinedOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {ErrFilterCriteria && <span className="invalid-feedback">{ErrFilterCriteria}</span>}
                        </div>
                    </div>

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>
                                    Select Operator <span className="star error">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    value={filterOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled>Select Operator</option>
                                    {combinedOptionsFilter.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {ErrFilterOperator && <span className="invalid-feedback">{ErrFilterOperator}</span>}
                            </div>
                        </div>
                    )}
                    {filterCriteria != 'created_at' && (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    Enter Criteria <span className="star error">*</span>
                                </label>
                                <input
                                    type="text" className="form-control"
                                    placeholder="Search..."
                                    inputValue={searchTag}
                                    //setInputValue={setSearchTag}
                                    onChange={handelSearchText}
                                    style={{marginRight: '10px'}}
                                />
                                {ErrSearchTag && <span className="invalid-feedback">{ErrSearchTag}</span>}
                            </div>
                        </div>
                    )}
                </div>
                {filterCriteria === 'created_at' && (
                    <div className="row m-2">
                        <div className="col-6">
                            <FromFieldDate
                                label="Start Date"
                                placeholder="Select Start Date"
                                inputValue={startDate}
                                setInputValue={setStartDate}
                                setErrorMsg={ErrStartDate}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="End Date"
                                placeholder="Select End Date"
                                inputValue={endDate}
                                setInputValue={setEndDate}
                                setErrorMsg={ErrEndDate}
                            />

                        </div>
                    </div>
                )}
                <div className="row mt-2">
                    <div className="col-6 text-center">
                        <button onClick={handleSearch} className='btn btn-info btn-md'>
                            <i className="bi bi-search"></i> Search
                        </button>
                    </div>
                    <div className="col-6 text-center">
                        <button
                            onClick={handleExportToCSV}
                            className='btn btn-primary btn-md'
                            disabled={loading}
                        ><i className="bi bi-download"></i> {loading ? 'Exporting...' : 'Export All Data to CSV'}
                        </button>
                    </div>
                </div>
            </div>
            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.sl_no}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                        label="Rows per page"
                                    >
                                        {[10, 25, 50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>
        </div>
    );
};

export default MyConsumableAssetList;
