import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {getERAssignmentList} from '../../services/user.service'
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function ListOfERAssignment() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [

        {"field": "erassignment_no", "editable": false, "hide": false, "headerName": "Assignment Number", "width": 230,renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'assignment_no': params.row.assignment_no,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/create-new-er-assignment?assignment_no=${EncryptID}`} >{params.row.erassignment_no}</a>)
          } 
        },
        {"field": "id", "editable": false, "hide": false, "headerName": "Action", "width": 230,
            renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'assignment_no': params.row.assignment_no,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/er-assessment-report?assignment_no=${EncryptID}`} >Submit Report</a>)
            }
        },
        {"field": "repair_category", "editable": false, "hide": false, "headerName": "Repair Category", "width": 230,
            renderCell: (params) => (
                <div>{(params.row.cat_ir=='ERAI')?('IT Assets'):'Other Assets'} </div>
            )
        },
        {"field": "equipment_listed_repairs", "editable": false, "hide": false, "headerName": "Earlier Repaired", "width": 150},
        {"field": "issue_caused", "editable": false, "hide": false, "headerName": "Any Accident", "width": 150},
        {"field": "under_warrenty", "editable": false, "hide": false, "headerName": "Covered under Warranty/AMC/Insurance", "width": 270},
        {"field": "endUser_name", "editable": false, "hide": false, "headerName": "Assigned To", "width": 230},
        {"field": "cat_ir", "editable": false, "hide": false, "headerName": "CAT of IR", "width": 120},
        {"field": "status", "editable": false, "hide": false, "headerName": "Status", "width": 150},
        {
            "field": "assignedDate", "editable": false, "hide": false, "headerName": "Created Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
    ];

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        // Show loader when the request starts
        setLoading(true);

        // Fetch division list
        getERAssignmentList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching rfid list:', error);
            })
            .finally(() => {
                // Hide loader when the request completes (either success or failure)
                setLoading(false);
            });
    }, []);  // Empty dependency array means this effect runs once on mount


    // readDivision();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>List of ER Assignment</h5></div>

            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}

        </div>
    )
}

export default ListOfERAssignment
