import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {getAuditLogsView} from '../../services/master.service';
import AuditLogChangeView from '../../atoms/AuditLogChangeView';
import * as CryptoJS from "crypto-js";
import {useLocation} from "react-router-dom";

function getFormatedDate(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
}
function AuditLogsView() { 
const [logId, setLogId] = useState('');
const [userName, setUserName] = useState('');
const [isConfirmOpen, setIsConfirmOpen] = useState(false);
const handleConfirmOpen = async (log_id,user_name) => {
    setLogId(log_id);
    setUserName(user_name);
    setIsConfirmOpen(true);
  };
const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };
// Add serial numbers


const columns = [
    {"field": "audit_id", "editable": false, "hide": true, "headerName": "Version", "width": 150},
    { "field": 'serialNumber', "editable": false, "hide": false, "headerName": 'Version', "width": 100, "sortable": false },
    {"field": "tags", "editable": false, "hide": false, "headerName": "Tag", "width": 280},
    {"field": "auditable_type", "editable": false, "hide": false, "headerName": "Module", "width": 180},
    {"field": "event", "editable": false, "hide": false, "headerName": "Activity", "width": 180},
    {"field": "user_name", "editable": false, "hide": false, "headerName": "Username", "width": 150,},
    {"field": "updated_at", "editable": false, "hide": false, "headerName": "Date & Time", "width": 150,
        renderCell: (params) => ( <p>{ getFormatedDate(params.row.updated_at)}</p> ) 
    },
    {"field": "id", "editable": false, "hide": false, "headerName": "Version", "width": 150,
    renderCell: (params) => (
        <div><button class="btn btn-primary btn-md text-right" onClick={() =>handleConfirmOpen(params.row.audit_id,params.row.serialNumber)}>View Change</button> </div>
      )
    },
];


    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const tag = query.get('tag');
    const method = query.get('method');
    const backUrl = query.get('back_url');
    const [back_url, setBackUrl] = useState(false);
    useEffect(() => {
        const decryptedBytes = CryptoJS.AES.decrypt(tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptModule = method?method:decrypt_data.module;
        const decryptTag = decrypt_data.tag;
        setBackUrl(backUrl?backUrl:'');
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':decryptTag,'module':decryptModule},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAuditLogsView(oData).then(res => {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, []);

    const dataWithSerialNumbers = items.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Add serial number starting from 1
    }));
    // readCategory();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Audit Log View</h5></div>
            </div>
            <div className='row'><div className='col-10'></div>
            {(back_url !== '' && back_url !== undefined && back_url !== false) ? ( 
            <div className='col-2 pb-2'><a href={back_url+'?tag=' + tag}><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>
            </div>): (
            <div className='col-2 pb-2'><a href={'/audit-log-list' }><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>
            </div>)}
            </div>
            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                rows={dataWithSerialNumbers}
                getRowId={(row) => row.audit_id}
                columns={columns}
                pageSize={50}
                autoHeight
                rowsPerPageOptions={[50]}
                //onCellEditStop={onCellEditStopFn}
                />
            </Box>
            <AuditLogChangeView
                isLogId = {logId}
                isVersion = {userName}
                isOpen={isConfirmOpen}
                onClose={handleConfirmClose}
                //onConfirm={handleConfirm}
            />
        </div>
    )
}

export default AuditLogsView
