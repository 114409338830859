import React, {useState} from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import { getBuyerQuotedPrice, saveFinalisedDeal,getBuyerList, checkFinalizeDinNo} from "../../../services/disposal.service";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function SingleFinalizeDeal() {

    const [items, setItems] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [success, setSuccess] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const [dinno, setDinno] = useState('');
    const [dinnoError, setdinNoError] = useState('');

    const [finalSalePrice, setFinalSalePrice] = useState('');
    const [finalSalePriceError, setFinalSalePriceError] = useState('');

    const [quotedReceivedDate, setQuotedReceivedDate] = useState('');
    const [quotedReceivedDateError, setQuotedReceivedDateError] = useState('');

    const [finalApprovalDate, setFinalApprovalDate] = useState('');
    const [finalApprovalDateError, setFinalApprovalDateError] = useState('');

    const [finalApprovalBy, setFinalApprovalBy] = useState('');
    const [finalApprovalByError, setFinalApprovalByError] = useState('');

    const [selectedBuyer, setSelectedBuyer] = useState('');


    const [assetInfo, setAssetInfo] = useState({});

    const [buyer, setBuyer] = useState('');
    const [setbuyer, buyerError] = useState('');
    const [buyer_no, setBuyerNo] = useState('');
    const [buyerNameOptions, setBuyerNameOptions] = useState([]);

    const [isFinalized, setIsFinalized] = useState(false); // Tracks if the DIN is finalized

    const updatedDinNo = assetInfo.din_no ? `${assetInfo.din_no}-F` : '';


    const handleBuyerDetails = (e) => {
        let bname = e.target.name;
        let value = e.target.value;
        setSelectedBuyer(e.target.value);
        setBuyerNo(e.target.key)
        //alert(value);


        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'din_no': dinno, 'buyer_name': value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getBuyerQuotedPrice(oData).then(function (res) {
            var aInfo1 = {};
            if (res?.data?.data?.res) {
                setErrMsg('');
                aInfo1 = res.data.data.res[0]['total_buyer_price_quoted'];
                setBuyer(aInfo1);
            } else {
                setAssetInfo({});
                setErrMsg('Quoted Price Not found');
            }
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var valid_Name = true;

        // Validate the inputs before submitting the form

        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
            valid_Name = false;
        } else {
            setdinNoError('');
        }


        if (valid_Name) {

            handleNextClick();
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const resetAssetDisposalInfo = function () {
        setDinno('');
    }

    const getTagInfo = function () {
        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
        } else {
            setdinNoError('');

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'din_no': dinno },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData = { 'token': EncryptData };

            checkFinalizeDinNo(oData).then(function (res) {
                if (res?.data?.data?.res && res.data.data.res.length > 0) {
                    const data = res.data.data.res[0];
                    setErrMsg('');
                    setAssetInfo(data); // Populate asset information
                    setBuyerNameOptions(res.data.data.res); // Populate buyer options

                    // Prefill form fields
                    setFinalSalePrice(data.buyer_final_price || '');
                    setQuotedReceivedDate(new Date(data.buyer_quote_received_date) || '');
                    setFinalApprovalDate(new Date(data.final_approval_date) || '');
                    setFinalApprovalBy(data.final_approval_by || '');
                    setSelectedBuyer(data.buyer_name || '');
                    setBuyerNo(data.buyer_no || '');
                    setBuyer(data.buyer_price_quoted || '');

                    // Mark as finalized, disabling the submit button
                    setIsFinalized(true);
                } else {
                    getBuyerList(oData).then(function (res) {
                        try {
                            if (res?.data?.data?.res && Array.isArray(res.data.data.res) && res.data.data.res.length > 0) {
                                const aInfo = res.data.data.res; // Extract array from response
                                setErrMsg(''); // Clear error message
                                setAssetInfo(aInfo[0]); // Set the first element to asset info
                                setBuyerNameOptions(aInfo); // Populate buyer options
                            } else {
                                // Handle case where `res` is empty or invalid
                                setAssetInfo({});
                                setBuyerNameOptions([]);
                                setErrMsg('DIN no not found');
                            }
                        } catch (error) {
                            console.error('Error processing response:', error);
                            setAssetInfo({});
                            setBuyerNameOptions([]);
                            setErrMsg('Error processing DIN data');
                        }
                    }).catch((error) => {
                        console.error('Error fetching buyer list:', error);
                        setAssetInfo({});
                        setBuyerNameOptions([]);
                        setErrMsg('Error fetching buyer list');
                    });

                }
            }).catch((err) => {
                console.error('Error fetching DIN details:', err);
                setErrMsg('Error fetching DIN details');
                resetAssetDisposalInfo();
                setIsFinalized(false); // Ensure button is enabled in case of error
            });
        }
    };


    const handleFormSubmitFinal = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (!(finalSalePrice) && finalSalePrice !== 0) {
            setFinalSalePriceError('Final sale price is required');
            valid_Name1 = false;
        } else {
            setFinalSalePriceError('');
        }
        if (!(buyer) && buyer !== 0) {
            buyerError('Final sale price is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }
        if (!(quotedReceivedDate) && quotedReceivedDate !== 0) {
            setQuotedReceivedDateError('Quoted Received Date is required');
            valid_Name1 = false;
        } else {
            setQuotedReceivedDateError('');
        }
        if (!(finalApprovalDate) && finalApprovalDate !== 0) {
            setFinalApprovalDateError('Quoted Approval Date is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalDateError('');
        }
        if (!(finalApprovalBy) && finalApprovalBy !== 0) {
            setFinalApprovalByError('Final approval by is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalByError('');
        }
        if (!selectedBuyer) {
            buyerError('Buyer is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }

        if (valid_Name1) {
            //alert('test1');
            saveFinalDeal(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const saveFinalDeal = function () {
        try {

            var obj = {
                'tag': assetInfo.tag,
                'din_no': assetInfo.din_no,
                'buyer_no': buyer_no,
                'buyer_name': selectedBuyer,
                'buyer_price_quoted': buyer,
                'buyer_final_price': finalSalePrice,
                'buyer_quote_received_date': (quotedReceivedDate ? getFormatedDate(quotedReceivedDate) : ''),
                'final_approval_date': (finalApprovalDate ? getFormatedDate(finalApprovalDate) : ''),
                'final_approval_by': finalApprovalBy,
                'gen_ref_no': updatedDinNo,

            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveFinalisedDeal(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted(false);
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Finalize Deal</h5>
                </div>
            </div>


            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                <div className="alert alert-danger" role="alert"><i
                    className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}


            <div className="tab-content">

                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>

                    <div className='row '>
                        <div className='col-12'>&nbsp;</div>
                        <div className='row'>
                            <div className='col-10'></div>
                            <div className='col-2'></div>
                        </div>
                        : <div className='row'>
                        <div className='col'><h5 className='align-right'>DIN No. </h5></div>

                        <div className='col'>
                            <input onChange={function (event) {
                                setDinno(event.target.value)
                            }} inputValue={assetInfo.tag} type="text"
                                   setInputValue={setDinno}
                                   setErrorMsg={setdinNoError}
                                   className="form-control"
                                   placeholder='Enter DIN No.'/>
                            <p className='invalid-feedback'>{dinnoError}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                        </div>
                    </div>

                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN No."
                                placeholder="DIN No."
                                inputValue={assetInfo?.din_no}// Use optional chaining here
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="DIN Created Date"
                                inputValue={assetInfo?.created_at ? getFormatedDate(assetInfo.created_at) : ''} // Use optional chaining here
                            />
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-4"></div>
                        <form name="" onSubmit={handleFormSubmit}>
                            <button className="btn btn-primary btn-md"> Next</button>
                        </form>
                        <div className="col-4"></div>
                    </div>

                </div>


                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN Number"
                                placeholder="DIN Number"
                                inputValue={assetInfo.din_no ? assetInfo.din_no : ''}
                                readOnly="readonly"
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-6" >
                    <div className="row" >
                        <div className="col-md-6" > 
                        <label htmlFor="selectBuyer">Select Buyer Name</label>
                        </div>
                        <div className="col-md-6" > 
                            <select
                                id="selectBuyer"
                                className="form-select"
                                onChange={(e) => {handleBuyerDetails(e)}} required value={selectedBuyer}>
                                <option value="">----- Select Buyer -----</option>
                                {buyerNameOptions && buyerNameOptions.length > 0 ? (
                                    buyerNameOptions.map((option) => (
                                        <option key={option.buyer_no} value={option.buyer_name}>
                                            {option.buyer_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No Data</option>
                                )}
                            </select>


                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Price Quoted"
                                inputValue={buyer}
                                setInputValue={setbuyer}
                                setErrorMsg={buyerError}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Final Sale Price W/o GST"
                                inputValue={finalSalePrice}
                                setInputValue={setFinalSalePrice}
                                setErrorMsg={finalSalePriceError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldDate
                                label="Final Sale Date"
                                placeholder="Final Sale Date"
                                inputValue={quotedReceivedDate}
                                setInputValue={setQuotedReceivedDate}
                                setErrorMsg={quotedReceivedDateError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldDate
                                label="Final Approval Date"
                                placeholder="Final Approval Date"
                                inputValue={finalApprovalDate}
                                setInputValue={setFinalApprovalDate}
                                setErrorMsg={finalApprovalDateError}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Final Approval By"
                                placeholder="Final Approval By"
                                inputValue={finalApprovalBy}
                                setInputValue={setFinalApprovalBy}
                                setErrorMsg={finalApprovalByError}
                            />
                        </div>
                    
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Gen Ref No"
                                inputValue={updatedDinNo}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <div className='text-center'>
                                <div className="d-flex justify-content-center">
                                    <form name="" onSubmit={resetAssetDisposalInfo}>
                                        <button className="btn btn-secondary btn-md me-3">Cancel</button>
                                    </form>

                                    {!formSubmitted && (
                                        <form name="" onSubmit={handleFormSubmitFinal}>
                                            <button
                                                className="btn btn-primary btn-md"
                                                disabled={formSubmitted || isFinalized} // Disable if already finalized
                                            >
                                                {isFinalized ? 'Already Finalized' : 'Submit'}
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>


            </div>


        </div>
    )
}

export default SingleFinalizeDeal;