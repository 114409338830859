import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import { getNotPhysicalVerifiedAssetsCSV, getFilterNotVerifiedData, getPhyVeriMaster } from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import { Parser } from "json2csv";

function getFormattedDate(dateString) {
    if (dateString) {
        const dte = new Date(dateString);
        const year = dte.getFullYear();
        const month = (dte.getMonth() + 1).toString().padStart(2, '0');
        const day = dte.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return null;
}

const columns = [
    { field: "tag", headerName: "TAG Name", width: 250 },
    { field: "parent_tag", headerName: "Parent TAG", width: 250 },
    { field: "value_of_asset", headerName: "Value of Assets", width: 150 },
    { field: "asset_name", headerName: "Asset Name", width: 250 },
    { field: "total_cost_without_tax", headerName: "Total Cost Without Tax", width: 180 },
    { field: "nature_of_presence", headerName: "Nature of Presence", width: 150 },
    { field: "verification_mode", headerName: "Verification Mode", width: 130, hide: true },
    { field: "company_name", headerName: "Company Name", width: 350, hide: true },
    { field: "division_name", headerName: "Division Name", width: 350, hide: true },
    { field: "method", headerName: "Method", width: 130, hide: true },
    { field: "last_verification_date", headerName: "Last Verification Date", width: 130, hide: true, renderCell: (params) => <p>{getFormattedDate(params.row.last_verification_date)}</p> },
    { field: "last_verification_remarks", headerName: "Last Verification remark", width: 180, hide: true },
    { field: "created_at", headerName: "Verification Date", width: 130, renderCell: (params) => <p>{getFormattedDate(params.row.created_at)}</p> },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const CustomLoader = () => (
        <GridOverlay>
            <CircularProgress color="primary" size={30} />
        </GridOverlay>
    );
const NotPhysicalVerificationReport = () => {
    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pvMaster, setPvMaster] = useState([]);
    const [pvMasterTable, setPvMasterTable] = useState('');
    const [typeOfAssets, setTypeOfAssets] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [filterAssets, setFilterAssets] = useState('');
    const [errFilterAssets, setErrFilterAssets] = useState(false);

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchPvMaster = async () => {
        setLoading(true);
        try {
            const offset = calculateOffset(page, pageSize);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
                param_data: { offset: 0, pageSize: 1000 },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { token: encryptedData };
            const res = await getPhyVeriMaster(oData);

            if (res?.data?.data?.length) {
                setPvMaster(res.data.data);
            }
        } catch (error) {
            console.error('Error fetching PV master:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFilteredData = async () => {
        setLoading(true);
        try {
            const offset = calculateOffset(page, pageSize);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
                param_data: { 'offset':offset, 'pageSize':pageSize, 'pv_table_no': pvMasterTable, 'type_of_assets': typeOfAssets, 'filter_of_assets': filterAssets },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { token: encryptedData };
            console.log('NotVerifiedData'); console.log(oData);
            const res = await getFilterNotVerifiedData(oData);
            console.log('NotVerifiedData'); console.log(res);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems([]);
                setTotalItems(0);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    

    useEffect(() => {
        fetchPvMaster();
        if (pvMasterTable) {
            fetchFilteredData();
        }
    }, [pvMasterTable,page, pageSize]);

    const handlePvMasterChange = (e) => {
        setPvMasterTable(e.target.value);
    };

    const handleFilter = async () => {
        if (pvMasterTable || typeOfAssets) {
            setErrMsg('');
            setPage(1); // Reset to the first page when filtering
            await fetchFilteredData();
        } else {
            setErrMsg('Please select physical master table OR select type of asset');
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchAssetsCSV = useCallback(async () => {
        setExporting(true);
        try {
            // Encrypt the selected filter parameters
            const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: {
                        pv_table_no: pvMasterTable, // Apply selected master table
                        type_of_assets: typeOfAssets, // Apply selected asset type
                        filter_of_assets: filterAssets // Apply selected filters
                    },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            const requestData = { token: encryptedData };
            const resCSV = await getNotPhysicalVerifiedAssetsCSV(requestData);

            // Set the filtered items to be exported
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setExporting(false);
        }
    }, [pvMasterTable, typeOfAssets, filterAssets]); // Ensure the dependencies match the selected filters

    const handleExportToCSV = async () => {
        // Trigger fetching filtered data
        await fetchAssetsCSV();
    };

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV(); // Export CSV when filtered data is available
        }
    }, [itemsCSV]);

    const generateCSV = () => {
        try {
            // Map JSON data to the desired CSV format
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag || '',
                parent_tag: item.parent_tag || '',
                value_of_asset: item.value_of_asset || '',
                asset_name: item.asset_name || '',
                total_cost_without_tax: item.total_cost_without_tax || '',
                nature_of_presence: item.nature_of_presence || '',
                verification_mode: item.verification_mode || '',
                company_name: item.company_name || '',
                division_name: item.division_name || '',
                method: item.method || '',
                last_verification_date: item.last_verification_date ? getFormattedDate(item.last_verification_date) : '',
                last_verification_remarks: item.last_verification_remarks || '',
                created_at: item.created_at ? getFormattedDate(item.created_at) : '',
            }));

            // Parse JSON to CSV and initiate download
            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Filtered_Assets_Reports.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Not Verified Assets Report</h5></div>
            </div>
            <div className="row">
                <div className="row form-fields">
                    <div className="label col-2">
                        <label> Physical Master Table </label>
                        <span className="star error"> * </span>
                    </div>
                    <div className="col-2">
                        <select onChange={handlePvMasterChange} className="form-select form-select-sm">
                            <option value="">Select PV Master Table</option>
                            {pvMaster.length > 0 ? (
                                pvMaster.map((option) => (
                                    <option key={option.id} value={option.id} selected={option.id === pvMasterTable}>
                                        {option.phy_varify_table_name}
                                    </option>
                                ))
                            ) : (<option value="">No Data</option>)}
                        </select>
                        {errMsg && <span className="invalid-feedback">{errMsg}</span>}
                    </div>
                    <div className="col-3">
                        <FromFieldSelect
                            label="Type of Assets"
                            placeholder="Type of Assets"
                            selectedValue={typeOfAssets}
                            setSelectedValue={setTypeOfAssets}
                            values={["Parent", "Child"]}
                            setErrorMsg={setErrMsg}
                        />
                    </div>
                    <div className="col-3">
                        <FromFieldSelect
                            label="Filter of Assets"
                            placeholder="Type of Assets"
                            selectedValue={filterAssets}
                            setSelectedValue={setFilterAssets}
                            values={["Asset", "Consumable"]}
                            setErrorMsg={errFilterAssets}
                        />
                    </div>
                    <div className="col-2">
                        <button onClick={handleFilter} className='btn btn-primary btn-md'>
                            <i className="bi bi-filter"></i> Filter
                        </button>
                    </div>
                </div>
                <div className="row form-fields mt-2 mb-2">
                    <div className="label col-2"></div>
                    <div className="col-3"></div>
                    <div className="col-4"></div>
                    <div className="col-3">
                        <button onClick={handleExportToCSV} className='btn btn-primary btn-md' disabled={exporting}>
                            <i className="bi bi-download"></i> {exporting ? 'Exporting...' : 'Export All Data to CSV'}
                        </button>
                    </div>
                </div>
            </div>
            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.sl_no}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        //Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ mr: 4 }}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        label="Rows per page"
                                    >
                                        {[10, 25, 50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>{size}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>
        </div>
    );
}

export default NotPhysicalVerificationReport;
