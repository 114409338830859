import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as CryptoJS from "crypto-js";
import { getAllGatePassTAG } from "../services/asset.service";
import {DataGrid} from "@mui/x-data-grid";

const GatePassMainStatusPopupBox = ({ gatePassNo, isOpen, onClose, onConfirm }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const columns = [
        {"field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250},
        {"field": "tag_name", "editable": false, "hide": false, "headerName": "TAG Name", "width": 250},
    ];

    useEffect(() => {
        // Only proceed if gatePassNo is defined and the modal is open
        if (!gatePassNo || !isOpen) return;

        const fetchData = async () => {
            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': { 'gatePassNo': gatePassNo },
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                const oData = { 'token': EncryptData };


                setLoading(true);

                const res = await getAllGatePassTAG(oData);
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                } else {
                    setItems([]);
                }
            } catch (error) {
                console.error('Error fetching rfid list:', error);
                setItems([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [gatePassNo, isOpen]); // Depend on gatePassNo and isOpen

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Confirm Box" style={modalStyles}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Gate Pass ({gatePassNo}) TAG Details</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div style={modalBody} className="text-center mt-2">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <DataGrid
                                rows={items}
                                getRowId={(row) => row.tag}
                                columns={columns}
                                pageSize={50}
                                autoHeight
                                rowsPerPageOptions={[50]}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '350px',
        height: '350px',
        textAlign: 'center',
    },
};

const modalBody = {
    position: 'relative',
    padding: '10px',
};

export default GatePassMainStatusPopupBox;
