import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import './AssetDetails.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FromFieldText from '../../../atoms/FromFieldText';
import {getAllocation, getDepreciation, getSTPI, getRepairLog} from '../../../services/asset.service';
import {getDepartmentList} from '../../../services/user.service';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import FromFieldNumber from '../../../atoms/FromFieldNumber';
import * as CryptoJS from "crypto-js";
//var months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
var months = {
    '1': 'JAN', '2': 'FEB', '3': 'MAR', '4': 'APR', '5': 'MAY', '6': 'JUN', '7': 'JUL',
    '8': 'AUG', '9': 'SEP', '10': 'OCT', '11': 'NOV', '12': 'DEC'
};
Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        dte.setMinutes(dte.getMinutes() + dte.getTimezoneOffset() + 330);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [
    {"field": "id", "hideable": true, "hide": true, "headerName": "Sl No"},
    {"field": "year", "hideable": true, "hide": true, "headerName": "Year"},
    {"field": "month", "hide": true, "headerName": "Month", valueGetter: (parms) => months[parms.row.month]},

    {"field": "tag", "hide": false, "headerName": "TAG", width: 250},
    {
        "field": "depreciation_date", "hide": false, "headerName": "Date", width: 150,
        renderCell: (params) => (
            <p>{params.value ? getFormatedDate(params.value) : ''}</p>
        )
    },

    {"field": "asset_ob", "hide": false, "headerName": "Asset OB"},
    {"field": "asset_added_value", "hide": false, "headerName": "Asset Added Value"},
    {"field": "asset_deleted_value", "hide": false, "headerName": "Asset Deleted Value"},
    {"field": "asset_cb", "hide": false, "headerName": "Asset CB"},

    {"field": "depreciation_ob", "hide": false, "headerName": "Depreciation OB"},
    {"field": "depreciation_added_value", "hide": false, "headerName": "Depreciation Added Value"},
    {"field": "depreciation_deleted_value", "hide": false, "headerName": "Depreciation Deleted Value"},
    {"field": "depreciation_adjusted_value", "hide": false, "headerName": "Depreciation Adjusted Value"},
    {"field": "depreciation_cb", "hide": false, "headerName": "Depreciation CB"},

    {"field": "wdv_ob", "hide": false, "headerName": "WDV OB"},
    {"field": "wdv_cb", "hide": false, "headerName": "WDV CB"}
];

const columns1 = [

    {"field": "tag", "hide": false, "headerName": "TAG", width: 250},
    {"field": "assignment_no", "hide": false, "headerName": "Assignment No", width: 250},
    {"field": "repair_category", "hide": false, "headerName": "Repair Category", width: 250},
    {
        "field": "created_at", "hide": false, "headerName": "Created Date", width: 150,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AssetDetails() {
    const [value, setValue] = useState(0);
    const [tag, setTag] = useState(0);
    //const [edittag, setEditTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState(0);
    const [assetDepriciation, setAssetDepriciation] = useState([]);
    const [assetRepairLogs, setAssetRepairLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allocationInfo, setAllocationInfo] = useState(0);
    const [EncryptData1, setEncryptData1] = useState([]);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    useEffect(() => {
        if (Tag !== '' && Tag !== null) {

            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTagid = decrypt_data.tag;

            getTagAutoInfo(decryptedTagid);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        /*getRoleList(oData).then(res=>{
            if(res?.data?.data?.length) setRoles(res.data.data);
        });*/
        getDepartmentList(oData).then(res => {
            if (res?.data?.data?.length) setDepartments(res.data.data);
        });
    }, [])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }
    const getTagAutoInfo = function (Tag) {
        const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': Tag, 'module': 'Addition'},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        //console.log(EncryptData1);
        //console.log("SKB2");
        setEncryptData1(decryptedBytes);
        let oData = {'token': EncryptData1}
        getSTPI(oData).then(function (res) {
            if (res?.data?.data) {
                setErrMsg('');
                var {assetInfo} = res.data.data;
                setAssetInfo(assetInfo);

            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        })
        try {
            setLoading('..');
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            getDepreciation(oData).then(function (res) {
                if (res.status === 200) {
                    var data = res?.data?.data;
                    if (data?.assetInfo && data?.assetDeprisiation) {
                        setAssetInfo(data.assetInfo);
                        setAssetDepriciation(data.assetDeprisiation);
                    } else {
                        setErrMsg('Assets Not Found');
                    }
                }
            });
            getRepairLog(oData).then(function (res) {
                if (res.status === 200) {
                    var data = res?.data?.data;
                    if (data?.assetRepairLog) {
                        console.log(data.assetRepairLog)
                        setAssetRepairLog(data.assetRepairLog);
                    }
                }
            });
            setLoading(false); // Stop loading
        } catch (err) {
            setLoading(false); // Stop loading 
            setAssetDepriciation('');
        }
        try {
            setLoading('..');
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getAllocation(oData1).then(function (res) {

                var info = {};
                //getTransferData(tag).then(function(res){
                if (res?.data?.data?.assetInfo) {
                    info = res.data.data.transferInfo;
                    setAllocationInfo(info);
                } else {
                    setAllocationInfo({});
                }
            });
            setLoading(false); // Stop loading
        } catch (err) {
            setLoading(false); // Stop loading in case of error
            setAllocationInfo({});
        }

    }
    const getTagInfo = function () {
        if (tag == '') {
            setErrMsg('Enter Assets');
        } else {
            setErrMsg('');
            try {
                setLoading('..');
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'tag': tag},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                let oData = {'token': EncryptData}
                getDepreciation(oData).then(function (res) {
                    if (res.status === 200) {
                        var data = res?.data?.data;
                        if (data?.assetInfo && data?.assetDeprisiation) {
                            setErrMsg('');
                            setAssetInfo(data.assetInfo);
                            setAssetDepriciation(data.assetDeprisiation);
                        } else {
                            setAssetInfo({});
                            setErrMsg('Assets Not Found');
                        }
                    }
                });
                setLoading(false); // Stop loading
            } catch (err) {
                setLoading(false); // Stop loading
                setAssetDepriciation('');
            }
            try {
                setLoading('..');
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'tag': tag},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                let oData1 = {'token': EncryptData}
                getAllocation(oData1).then(function (res) {
                    var info = {};
                    //getTransferData(tag).then(function(res){
                    if (res?.data?.data?.assetInfo) {
                        info = res.data.data.transferInfo;
                        setAllocationInfo(info);
                    } else {
                        setAllocationInfo({});
                    }
                });
                setLoading(false); // Stop loading
            } catch (err) {
                setLoading(false); // Stop loading in case of error
                setAllocationInfo({});
            }
        }
    }
    const sSub_Module_Id = sessionStorage.getItem('LoggedInSubModuleId');
    const aSub_Module_Id = sSub_Module_Id.split(',');
    //console.log('aSub_Module_Id'); console.log(aSub_Module_Id);
    return (
        <div className="container ">
            {success ? (
                <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}
                </div>
            ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                    className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}
            { 
                (Tag !== '' && Tag !== null) ? <div className='row'>
                    <div className='col-8'></div>
                    <div className='col-4'>
                        <a href={'/single-asset?tag=' + Tag}>
                        <button className='btn btn-info'><i className="bi bi-pencil-square"></i> Edit</button>
                    </a>&nbsp;
                        <a href={'/asset-list'}>
                        <button className='btn btn-secondary'><i className="bi bi-arrow-left"></i> Back</button>
                    </a>&nbsp;
                    {(aSub_Module_Id.indexOf('100') > -1) ? (<>
                        <a href={'/audit-log-view?method=Addition&back_url=asset-details&tag=' + Tag}>
                        <button className='btn btn-warning'><i className="bi bi-clock-history"></i> View Audit Log</button>
                        </a></>):(<></>)} &nbsp;</div>
                        </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input onChange={function (event) {
                        setTag(event.target.value)
                    }} inputValue={assetInfo.tag} type="text" className="form-control"
                                 placeholder='Enter the asset TAG'/></div>
                    <div className='col'>
                        <button onClick={getTagInfo} className='btn btn-info btn-sm'><i className="bi bi-search"></i> Search
                        </button>
                    </div>
                    {loading ? (<div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{loading}</span>
                    </div>) : ('')}</div>
            }

<div className='row h-100'>
    <div className='col h-100'>
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Information" {...a11yProps(0)} />
                    <Tab label="Capitalization" {...a11yProps(1)} />
                    <Tab label="Depriciation" {...a11yProps(2)} />
                    <Tab label="Allocation" {...a11yProps(3)} />
                    <Tab label="Repair Log" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="TAG"
                                                        inputValue={assetInfo.tag}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Parent TAG"
                                                        inputValue={assetInfo.parent_tag}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Year of Addition"
                                                        inputValue={assetInfo.year_of_addition}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Type of Addition"
                                                        inputValue={assetInfo.type_of_addition}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Project Marking"
                                                        inputValue={assetInfo.project_marking}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="PO No (If Any)"
                                                        inputValue={assetInfo.po_no}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Supplier Code (if Any)"
                                                        inputValue={assetInfo.supplier_code}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Name of the Supplier"
                                                        inputValue={assetInfo.supplier_name}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Invoice No"
                                                        inputValue={assetInfo.invoice_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Invoice Date"
                                                        inputValue={assetInfo.invoice_date ? getFormatedDate(assetInfo.invoice_date) : ''}/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Bill of Entry No"
                                                        inputValue={assetInfo.boe_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Date of BOE"
                                                        inputValue={assetInfo.boe_date ? getFormatedDate(assetInfo.boe_date) : ''}/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Shipping / AWB No"
                                                        inputValue={assetInfo.shipping_awb_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Date of Shipping/AWB"
                                                        inputValue={assetInfo.shipping_awb_date ? getFormatedDate(assetInfo.shipping_awb_date) : ''}/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Accounting Voucher No."
                                                        inputValue={assetInfo.accounting_voucher_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Internal Approval No"
                                                        inputValue={assetInfo.internal_approval_no}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Cost Center Code"
                                                        inputValue={assetInfo.cost_center_code}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Cost Center Name"
                                                        inputValue={assetInfo.cost_center_name}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Invoice Currency"
                                                        inputValue={assetInfo.invoice_currency}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Exchange Rate"
                                                        inputValue={assetInfo.exchange_rate}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Basic Cost p.u in Fx"
                                                          inputValue={assetInfo.invoice_value_fx}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Basic Cost p.u in INR"
                                                          inputValue={assetInfo.invoice_value_inr}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly"
                                                          label="Forwarding & Insurance"
                                                          inputValue={assetInfo.forwarding_and_insurance}/>
                    </div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Installation"
                                                          inputValue={assetInfo.installation}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Errection"
                                                          inputValue={assetInfo.errection}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Total Cost Without Tax p.u"
                                                          inputValue={assetInfo.total_cost_without_tax}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="customs"
                                                          inputValue={assetInfo.customs}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="CGST"
                                                          inputValue={assetInfo.cgst}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="SGST"
                                                          inputValue={assetInfo.sgst}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="IGST"
                                                          inputValue={assetInfo.igst}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Other Taxes"
                                                          inputValue={assetInfo.other_taxes}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Total Tax p.u"
                                                          inputValue={assetInfo.total_tax}/></div>
                </div>

                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Total Cost with Tax p.u"
                                                          inputValue={assetInfo.value_of_asset}/></div>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Qty"
                                                        inputValue={assetInfo.qty}/></div>

                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Unit Of Measure"
                                                        inputValue={assetInfo.unit_of_measure}/></div>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Name of the Asset (as per Invoice)"
                                                        inputValue={assetInfo.asset_name}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Detailed Description of the Asset"
                                                        inputValue={assetInfo.asset_description}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Nature of Presence"
                                                        inputValue={assetInfo.nature_of_presence}/></div>
                </div>
                <div className='row'>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Nature of Addition"
                                                          inputValue={assetInfo.nature_of_addition}/></div>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Principal Asset to which Cost is Added"
                                                        inputValue={assetInfo.principal_asset_tag}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Unique Sl No of the Asset"
                                                        inputValue={assetInfo.asset_serial_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Make / Manufacturer"
                                                        inputValue={assetInfo.manufacturer}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Model No"
                                                        inputValue={assetInfo.model_no}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Part No"
                                                        inputValue={assetInfo.part_no}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="TAG"
                                                        inputValue={assetInfo.tag}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Comp Ref. No."
                                                        inputValue={assetInfo.comp_ref_no}/></div>
                </div>
                <div className='row'>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Asset TAG Name"
                                                          inputValue={assetInfo.tag_name}/></div>
                    <div className='col'><FromFieldText readOnly="readonly" label="Asset TAG Branch"
                                                        inputValue={assetInfo.tag_division}/></div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Control Department"
                                                          inputValue={(departments && departments.length) > 0 ? (
                                                            departments
                                                                .filter(option => assetInfo.ownership_id === option.id)
                                                                .map(option => option.department_name)
                                                                .join(', ')
                                                        ) : ('')}/></div>
                    <div className='col'>
                        <FromFieldText readOnly="readonly" label="Security Seal Date"
                                       inputValue={assetInfo.security_seal_date ? getFormatedDate(assetInfo.security_seal_date) : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="TAG"
                                                        inputValue={assetInfo.tag}/></div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Class of Asset"
                                                        inputValue={assetInfo.class_of_asset}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Record Start Date"
                                                          inputValue={getFormatedDate(assetInfo.record_start_date)}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Parent TAG"
                                                        inputValue={assetInfo.parent_tag}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Depreciation mode"
                                                          inputValue={assetInfo.cap_type_of_asset}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Date of Capitalization / Asset Ready To Use"
                                                        inputValue={getFormatedDate(assetInfo.asset_in_use_date)}/>
                    </div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Type of Depreciation"
                                                          inputValue={assetInfo.type_of_depreciation}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly"
                                                        label="Depreciation Rate (No. of years)"
                                                        inputValue={assetInfo.depreciation_rate}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="End Date"
                                                          inputValue={getFormatedDate(assetInfo.depreciation_end_date)}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Accounting Code of Asset"
                                                        inputValue={assetInfo.accounting_code}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly"
                                                          label="Accounting Name of the Asset"
                                                          inputValue={assetInfo.accounting_name}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Financials Classification"
                                                        inputValue={assetInfo.category_name}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Put to Use Date [IT]"
                                                          inputValue={getFormatedDate(assetInfo.put_to_use_date)}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Final Asset Capitalization Flag"
                                                        inputValue={assetInfo.capitalization_flag}/></div>
                    <div className='col-6'><FromFieldText readOnly="readonly" label="Is Tax Capitalized"
                                                          inputValue={assetInfo.is_tax_capitalized}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Invoice Currency"
                                                        inputValue={assetInfo.invoice_currency}/></div>
                    <div className='col-6'><FromFieldNumber readOnly="readonly" label="Exchange Rate"
                                                          inputValue={assetInfo.exchange_rate}/></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                        label="Is 100% Depreciable Asset"
                                                        inputValue={assetInfo.is_100_perc_depreciable}/>
                    </div>
                    <div className='col-6'><FromFieldNumber readOnly="readonly"
                                                            label="Pre Capitalized Asset Value"
                                                            inputValue={assetInfo.pre_capitalized_asset_value}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly"
                                                          label="Is any other Accg GAAP Applicable"
                                                          inputValue={assetInfo.is_any_other_accg_gaap_applicable}/>
                    </div>
                    <div className='col-6'><FromFieldNumber readOnly="readonly"
                                                            label="I-GAAP - Limit for 100% Depreciable Asset (Rs)"
                                                            inputValue={assetInfo.igaap_limit_amount}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldNumber readOnly="readonly" label="Salvage Value"
                                                            inputValue={assetInfo.salvage_value}/>
                    </div>
                    <div className='col-6'><FromFieldNumber readOnly="readonly"
                                                          label="Capitalized Value (I-GAAP)"
                                                          inputValue={assetInfo.igaap_capitalized_value}/></div>
                </div>
                <div className='row'>
                    <div className='col'>
                    </div>
                    <div className='col-6'>
                    </div>
                </div>
                { ((assetInfo.is_any_other_accg_gaap_applicable!=='' && assetInfo.is_any_other_accg_gaap_applicable=='Yes')) ? <div>
                <div className="row">
                    <h5>&nbsp;</h5>
                    <hr></hr>
                    <h5>Other-GAAP</h5>
                    </div>
                    <div className="row">
                    <div className="col-6">
                    <FromFieldText star="*"
                                label="Record Start Date [Other-GAAP]"
                                inputValue={ getFormatedDate(assetInfo.other_record_start_date)}
                                readOnly="readonly"
                            />
                    
                    </div>
                    <div className="col-6">
                    <FromFieldText star="*"
                                label="Is 100% Depreciable Asset [Other-GAAP]"
                                inputValue={assetInfo.is_100_perc_other_depreciable}
                                readOnly="readonly"
                            />
                    
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-6">
                        <div className="row"><div className="col-md-6">
                        <label> Depreciation Rate(No. of years) [Other-GAAP] <span className="star error"> * </span></label>
                        </div>
                        <div className="col-md-6">
                            <input type="number" value={assetInfo.other_depreciation_rate} className="form-control" placeholder="Depreciation Rate[Other-GAAP]" readOnly="readonly" />
                            
                        </div>
                        </div>
                    </div>
                    <div className="col-6">
                    <FromFieldText  //star="*"
                        label="End Date [Other-GAAP]"
                        inputValue={getFormatedDate(assetInfo.depreciation_other_end_date)} 
                        readOnly="readOnly"
                        />
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-6">
                        <FromFieldNumber   star="*"
                        label="Other-GAAP - Limit for 100% Depreciable Asset (Rs)"
                        inputValue={assetInfo.othergaap_limit_amount} 
                        readOnly="readonly"
                        
                        />
                        </div>
                    <div className="col-6">
                        <FromFieldNumber star="*"
                        label="Capitalized Value (Other GAAP)" 
                        inputValue={assetInfo.othergaap_capitalized_value} 
                        readOnly="readonly"
                        />        
                    </div>
                    </div>
                </div>:('') }
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className='row'>
                    <div className='col'>
                        <Box sx={{height: 500, width: '100%'}}>
                            <DataGrid
                                rows={assetDepriciation}
                                getRowId={(row) => row.id}
                                columns={columns}
                                pageSize={50}
                                columnBuffer={50}
                                //autoHeight
                                rowsPerPageOptions={[50]}
                                onCellEditStop={onCellEditStopFn}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            /></Box>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="TAG"
                                                        inputValue={(allocationInfo) ? allocationInfo.tag : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Division"
                                                        inputValue={(allocationInfo) ? allocationInfo.division_name : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Premises"
                                                        inputValue={(allocationInfo) ? allocationInfo.premises : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Floor"
                                                        inputValue={(allocationInfo) ? allocationInfo.floor : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Site ID"
                                                        inputValue={(allocationInfo) ? allocationInfo.site_id : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Rack Id"
                                                        inputValue={(allocationInfo) ? allocationInfo.rack_id : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="User Type"
                                                        inputValue={(allocationInfo) ? allocationInfo.user_type : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="User Department"
                                                        inputValue={(allocationInfo) ? allocationInfo.user_department : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Employee ID"
                                                        inputValue={(allocationInfo) ? allocationInfo.employee_id : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Employee Name"
                                                        inputValue={(allocationInfo) ? allocationInfo.employee_name : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="User Mail ID"
                                                        inputValue={(allocationInfo) ? allocationInfo.user_mail_id : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Eligible for Alternative Verification"
                                                        inputValue={(allocationInfo) ? allocationInfo.alternative_verification : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Start Date of Allocation"
                                                        inputValue={(allocationInfo) ? allocationInfo.effective_date : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col'><FromFieldText readOnly="readonly" label="Select for child allocation"
                                                        inputValue={(allocationInfo) ? allocationInfo.child_allocation : ''}/>
                    </div>
                    <div className='col-6'></div>
                </div>

            </TabPanel>

            <TabPanel value={value} index={4}>
                <div className='row'>
                    <div className='col'>
                        <Box sx={{height: 500, width: '100%'}}>
                            <DataGrid
                                rows={assetRepairLogs}
                                getRowId={(row) => row.id}
                                columns={columns1}
                                pageSize={50}
                                columnBuffer={50}
                                //autoHeight
                                rowsPerPageOptions={[50]}
                                onCellEditStop={onCellEditStopFn}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            /></Box>
                    </div>
                </div>
            </TabPanel>

        </Box>
    </div>
</div>
</div>
)
}

export default AssetDetails