import React, {useEffect, useState} from 'react';
import {getTAGDetails, getNotAllocatedFlag, saveGatePass, 
    saveGatePassRemark,getAllCostCenterData,getCostCenterByCompany} from '../../services/asset.service';
import Select from 'react-select';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from '../../atoms/FromFieldTextArea';
import {getLastGatePassNo} from '../../services/user.service';
import FromFieldText from "../../atoms/FromFieldText";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldTextER from "../../atoms/FromFieldTextER";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

function GatePass() {
    const [activeTab, setActiveTab] = useState(0); // State to track the active tab
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOptions1, setListData1] = useState([]);
    const [info, setInfo] = useState([]);

    const [remarks, setRemarks] = useState({});
    const [items, setItems] = useState([]);
    const [foundAssets, setFoundAssets] = useState();

    const [textBoxValue, setTextBoxValue] = useState('');

    const [gatePassNo, setGatePassNo] = useState('');
    const [ErrGatePassNo, setErrGatePassNo] = useState(false);

    const [gatePassDate, setGatePassDate] = useState('');
    const [ErrGatePassDate, setErrGatePassDate] = useState(false);

    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundAssets.filter((asset) => {
                return asset.tag.startsWith(keyword);
            }); //console.log(results);
            setFoundAssets(results);
        } else {
            setFoundAssets(foundAssets);
            // If the text field is empty, show all assets
        }
        //console.log('listOptions1--');
        //setListData1(listOptions1);
        setName(keyword);
    };

    const [catOfEmp, setCatOfEmp] = useState('');
    const [ErrCatOfEmp, setErrCatOfEmp] = useState(false);

    const [gatePassType, setGatePassType] = useState('');
    const [ErrGatePassType, setErrGatePassType] = useState(false);

    const [employeeID, setEmployeeID] = useState('');
    const [ErrEmployeeID, setErrEmployeeID] = useState(false);

    const [costCenter, setCostCenter] = useState('');
    const [ErrCostCenter, setErrCostCenter] = useState(false);

    const [titles, setTitles] = useState('');
    const [ErrTitles, setErrTitles] = useState(false);

    const [designation, setDesignation] = useState('');
    const [ErrDesignation, setErrDesignation] = useState(false);

    const [vendorName, setVendorName] = useState('');
    const [ErrVendorName, setErrVendorName] = useState(false);

    const [address, setAddress] = useState('');
    const [ErrAddress, setErrAddress] = useState(false);

    const [destinationCity, setDestinationCity] = useState('');
    const [ErrDestinationCity, setErrDestinationCity] = useState(false);

    const [destinationCountry, setDestinationCountry] = useState('');
    const [ErrDestinationCountry, setErrDestinationCountry] = useState(false);

    const [expectedDate, setExpectedDate] = useState('');
    const [ErrExpectedDate, setErrExpectedDate] = useState(false);

    const [reasonTakingOut, setReasonTakingOut] = useState('');
    const [ErrReasonTakingOut, setErrReasonTakingOut] = useState(false);

    const [descriptions, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState([]);

    const [cost_center_codes, setCost_Center_Codes] = useState([]);

    const [costcentercode, setCostCenterCode] = useState('');
    const [ErrCostCenterCode, setErrCostCenterCode] = useState(false);

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getNotAllocatedFlag(oData).then(res => {
            if (res?.data?.data?.length) setFoundAssets(res.data.data);
        });
        getCostCenterByCompany(oData).then(res => {
            if (res?.data?.data?.length) setCost_Center_Codes(res.data.data);
        });
        getAllCostCenterData(oData).then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
        });
        
    }, []);

    const pushListOptions = (obj) => {
        var flag = 0;
        setLoading('..');
        listOptions1.forEach((elem) => {
            if (obj.value === elem.value && obj.label === elem.label) {
                flag = 1;
            }
        });
        setLoading(false);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };

    const [name, setName] = useState('');

    const optionCostCenterList = [];
    cost_center_codes && cost_center_codes.length > 0 ? (
        cost_center_codes.map((option) => (
            optionCostCenterList.push({value: option.cost_center_code, label: option.cost_center_code})
        ))
    ) : (
        optionCostCenterList.push({value: "", label: "No Data"})
    )

    const [selectedOptionsCostCenterList, setOptionsCostCenterList] = useState();

    function handleCostCenter(data) {
        //console.log(data);
        setOptionsCostCenterList(data);
        setCostCenterCode(data.value);
        
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (listOptions1.length === 0) { //alert(listOptions1.length);
            setValidName(false);
            alert('Please select at least one Asset');
            valid_Name = false;
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            //saveData();
            handleNextClick();
        }
    }
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (!TEXT_REGEX.test(catOfEmp)) { 
            setErrCatOfEmp('Category is required');
            valid_Name = false; 
        } else {
            setErrCatOfEmp('');
        }
        if (!TEXT_REGEX.test(gatePassType)) { 
            setErrGatePassType('Gate Pass type is required');
            valid_Name = false;
        } else {
            setErrGatePassType('');
        } if (catOfEmp === 'Employee') {
            if (!TEXT_REGEX.test(employeeID)) { 
                setErrEmployeeID('Employee Id is required');
                valid_Name = false;
            } else {
                setErrEmployeeID('');
            }
            if (!TEXT_REGEX.test(costcentercode)) { 
                setErrCostCenterCode('Cost Center is required');
                valid_Name = false;
            } else {
                setErrCostCenterCode('');
            }
        } else {
            setErrEmployeeID(''); // Clear any previous error if not 'Employee'
        }
        if (catOfEmp === 'Vendor') {
            if (!TEXT_REGEX.test(vendorName)) {
                setErrVendorName('Vendor name is required');
                valid_Name = false;
            } else {
                setErrVendorName('');
            }
        } else {
            setErrEmployeeID(''); // Clear any previous error if not 'Employee'
        }
        if (!TEXT_REGEX.test(titles)) { 
            setErrTitles('Title is required');
            valid_Name = false; 
        } else {
            setErrTitles('');
        }if (!TEXT_REGEX.test(designation)) {
            setErrDesignation('Title is required');
            valid_Name = false;
        } else {
            setErrDesignation('');
        }if (!TEXT_REGEX.test(gatePassNo)) {
            setErrGatePassNo('Gate Pass no is required');
            valid_Name = false;
        } else {
            setErrGatePassNo('');
        }if (!gatePassDate) {
            setErrGatePassDate('Gate Pass Date is required');
            valid_Name = false;
        } else {
            setErrGatePassDate('');
        }if (!TEXT_REGEX.test(address)) {
            setErrAddress('Material taken to (Address) is required');
            valid_Name = false;
        } else {
            setErrAddress('');
        }if (!TEXT_REGEX.test(destinationCity)) {
            setErrDestinationCity('Destination city is required');
            valid_Name = false;
        } else {
            setErrDestinationCity('');
        }if (!TEXT_REGEX.test(destinationCountry)) {
            setErrDestinationCountry('Destination country is required');
            valid_Name = false;
        } else {
            setErrDestinationCountry('');
        }if (gatePassType === "Returnable" && !expectedDate) {
            setErrExpectedDate('Expected date is required');
            valid_Name = false;
        } else {
            setErrExpectedDate('');
        }if (!TEXT_REGEX.test(reasonTakingOut)) {
            setErrReasonTakingOut('Reason taking out is required');
            valid_Name = false;
        } else {
            setErrReasonTakingOut('');
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert('save'); 
            saveData();
            //handleNextClick();
        }
    }

    const resetUserInfo = () => {
        setCatOfEmp('');
        setGatePassType('');
        setEmployeeID('');
        setCostCenter('');
        setTitles('');
        setDesignation('');
        setDescription('');
        setGatePassNo('');
        setGatePassDate('');
        setVendorName('');
        setAddress('');
        setDestinationCity('');
        setDestinationCountry('');
        setExpectedDate('');
        setReasonTakingOut('');

    };
    const saveData = () => { 
        try {

            var obj = {
                'catOfEmp': catOfEmp,
                'gatePassType': gatePassType,
                'employeeID': employeeID,
                'costCenter': costcentercode,
                'titles': titles,
                'designation': designation,
                'gatePassNo': gatePassNo,
                'gatePassDate': gatePassDate,
                'vendorName': vendorName,
                'address': address,
                'destinationCity': destinationCity,
                'destinationCountry': destinationCountry,
                'expectedDate': expectedDate,
                'reasonTakingOut': reasonTakingOut,
                //'existing_ownership_id': sessionStorage.getItem('LoggedInDepartmentId'),
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                //'status':'4'
            };
            console.log(obj);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveGatePass(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            var obj1 = {

                'gatePassNo': gatePassNo,
                'tag': listOptions1,
                'remark': descriptions,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};
            saveGatePassRemark(oData1).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };
    const handleMoveList1 = (e) => {
        const selectedOptions = [];
        //alert('dd');
        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleMoveList2 = (e) => {
        const selectedOptions2 = [];
        var options2 = document.getElementById('lstBox2').options;
        //console.log(options1);
        for (var i = 0, l = options2.length; i < l; i++) {
            if (options2[i].selected) {
                var option1 = {
                    label: options2[i].label,
                    value: options2[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions2.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleAllMoveList1 = (e) => {
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
        setListData1(listOptions1);
    }
    const handleAllRemove = (e) => {
        const confirmRemove = window.confirm("Are you sure you want to remove all TAG from list?");
        if (confirmRemove) {
            setListData1([]);
        }
    }
    const handleNextClick = () => {
        setActiveTab(activeTab + 1);

        // Extract all tag values from the listOptions1 array
        const tagValues = listOptions1.map(option => option.value);

        if (tagValues.length > 0) {
            let TagEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'tags': tagValues },  // Send multiple tags as an array
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            let oTagData = { 'token': TagEncryptData };

            // Call the API to send the encrypted tag values
            getTAGDetails(oTagData).then(function (res) {
                if (res?.data?.data) {
                    setInfo(res.data.data) ;
                    //console.log('TAG Info: ', Info);
                }
            }).catch((err) => {
                console.error("Error fetching TAG details: ", err);
            });
        } else {
            console.error("No tags selected");
        }
    };


    const handlePrevClick = () => {
        // Handle the "Previous" button click to move to the previous tab
        setActiveTab(activeTab - 1);
    };
    const handleCATofEmp = (event) => {
        setCatOfEmp(event.target.value);  // Update the state with the selected value
    };

    const handleGatePassType = (event) => {
        setGatePassType(event.target.value);  // Update the state with the selected value

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        // After updating GatePassType, call getLastGatePassNo and update GatePassNo
        console.warn(oData);
        getLastGatePassNo(oData).then(res => {

            let lastDinId = ((res.data.total) ? res.data.total : 0) + 1;
            let newlastDinId = lastDinId.toString().padStart(4, '0');
            let currentYear = new Date().getFullYear();
            let divisionCode = sessionStorage.LoggedInDivisionCode;

            // Logic to add 'ER' or 'VR' based on CATofEmp and GatePassType
            let prefix = '';

            if (catOfEmp === 'Employee') {
                if (event.target.value === 'Returnable') {
                    prefix = 'ER';  // Employee and Returnable
                } else if (event.target.value === 'Non Returnable') {
                    prefix = 'ENR';  // Employee and Non-Returnable
                }
            } else if (catOfEmp === 'Vendor') {
                if (event.target.value === 'Returnable') {
                    prefix = 'VR';  // Vendor and Returnable
                } else if (event.target.value === 'Non Returnable') {
                    prefix = 'VNR';  // Vendor and Non-Returnable
                }
            }
            setGatePassNo('GP' + divisionCode +  currentYear +  prefix + newlastDinId);
        });
    };

    const handleDescriptionChange = (index, value) => {
        const newDescriptions = [...descriptions];
        newDescriptions[index] = value;
        setDescription(newDescriptions);
    };

    return (
        <div className='container'>

            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}

            <div className="tab-content">
                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                    <div>

                        <div className="title">
                            <h5>Gate Pass</h5>

                        </div>

                        {foundAssets && foundAssets.length > 0 && (
                            loading ? (
                                <div>Loading...</div> // Add your loader component here
                            ) : (
                                <div className="subject-info-box-1">

                                    <input type="search" value={name} onChange={filter} className="form-control"
                                           placeholder="Filter"/>
                                    <select multiple="multiple" id="lstBox1" className="form-select form-select-sm">
                                        {foundAssets.map((option) => (
                                            <option value={option.tag}>{option.tag}</option>
                                        ))}
                                    </select>
                                    <p>Total count: {foundAssets.length}</p>

                                </div>
                            )
                        )}

                        {(!foundAssets || foundAssets.length === 0) && <p>No results</p>}


                        <div className="subject-info-arrows text-center">{loading ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{loading}</span>
                            </div>) : ('')}
                            {/*<button onClick={filter} value="" className="btn btn-info btn-sm refresh">Refresh</button>*/}
                            <br/><br/>
                            <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList1} value='>'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList2} value='<'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All'
                                   className="btn btn-default"/>
                        </div>
                        <div className="subject-info-box-2">
                            <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
                                {listOptions1 && listOptions1.length > 0 ? (
                                    listOptions1.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))
                                ) : (
                                    <h1>No results</h1>
                                )}
                            </select>
                            <p>Total count: {listOptions1.length}</p>
                        </div>

                        <div className="clearfix"></div>


                        <form name="" onSubmit={handleSubmit}>
                            <button className="btn btn-primary btn-md float-end"> Next</button>
                        </form>

                    </div>
                </div>

                <div className={`container tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>
                    <div>

                        <div className="title">

                            <div className='row'>
                                <div className="col-6">
                                    <div className="row ">
                                        <div className="col-6">
                                            <label>CAT <span className="star error"> * </span></label>
                                        </div>
                                        <div className="col-6">
                                            <select onChange={handleCATofEmp}
                                                    className="form-select form-select-sm mb-3">
                                                <option value="">Select One</option>
                                                <option value="Employee"
                                                        selected={catOfEmp === "Employee" ? "selected" : ''}>Employee
                                                </option>
                                                <option value="Vendor"
                                                        selected={catOfEmp === "Vendor" ? "selected" : ''}>Vendor
                                                </option>
                                            </select>
                                            <span className="invalid-feedback">{ErrCatOfEmp}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="row ">
                                        <div className="col-6">
                                            <label>Gate Pass Type <span className="star error"> * </span></label>
                                        </div>
                                        <div className="col-6">
                                            <select onChange={handleGatePassType}
                                                    className="form-select form-select-sm mb-3">
                                                <option value="">Select One</option>
                                                <option value="Returnable"
                                                        selected={gatePassType === "Returnable" ? "selected" : ''}>Returnable
                                                </option>
                                                <option value="Non Returnable"
                                                        selected={gatePassType === "Non Returnable" ? "selected" : ''}>Non
                                                    Returnable
                                                </option>
                                            </select>
                                            <span className="invalid-feedback">{ErrGatePassType}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Conditional rendering based on the selected CAT */}
                            {catOfEmp === "Employee" && (
                                <div className='row'>
                                    <div className="col-6">
                                        <FromFieldText
                                            star="*"
                                            label="Employee ID"
                                            inputValue={employeeID}
                                            setInputValue={setEmployeeID}
                                            setErrorMsg={ErrEmployeeID}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        {/*<FromFieldText
                                            star="*"
                                            label="Cost Centre"
                                            inputValue={costCenter}
                                            setInputValue={setCostCenter}
                                            setErrorMsg={ErrCostCenter}
                                        />*/}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Cost Center Code </label>
                                            </div>
                                            <div className="col-md-6">
                                                <Select
                                                    options={optionCostCenterList}
                                                    placeholder="Select One"
                                                    value={selectedOptionsCostCenterList}
                                                    onChange={handleCostCenter}
                                                />
                                                <span className="invalid-feedback"> {ErrCostCenterCode} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className='row'>

                                <div className="col-6">
                                    <FromFieldText
                                        star="*"
                                        label="Smt / Sri"
                                        inputValue={titles}
                                        setInputValue={setTitles}
                                        setErrorMsg={ErrTitles}
                                    />
                                </div>
                                <div className='col-6'>
                                    <FromFieldText
                                        star="*"
                                        label="Designation"
                                        inputValue={designation}
                                        setInputValue={setDesignation}
                                        setErrorMsg={ErrDesignation}
                                    />
                                </div>

                            </div>

                            <div className='row'>

                                <div className="col-6">
                                    <FromFieldText
                                        readOnly
                                        star="*"
                                        label="Gate Pass Number"
                                        inputValue={gatePassNo}
                                        setInputValue={setGatePassNo}
                                        setErrorMsg={ErrGatePassNo}
                                    />
                                </div>
                                <div className='col-6'>
                                    <FromFieldDate
                                        star="*"
                                        label="Gate Pass Date"
                                        inputValue={gatePassDate}
                                        setInputValue={setGatePassDate}
                                        setErrorMsg={ErrGatePassDate}
                                    />
                                </div>

                            </div>

                            <div className='row'>

                                {catOfEmp === "Vendor" && (
                                    <div className="col-6">
                                        <FromFieldText
                                            star="*"
                                            label="Vendor Company Name"
                                            inputValue={vendorName}
                                            setInputValue={setVendorName}
                                            setErrorMsg={ErrVendorName}
                                        />
                                    </div>
                                )}
                                <div className='col-6'>
                                    <FromFieldText
                                        star="*"
                                        label="Material taken to (Address)"
                                        inputValue={address}
                                        setInputValue={setAddress}
                                        setErrorMsg={ErrAddress}
                                    />
                                </div>

                            </div>

                            <div className='row'>

                                <div className="col-6">
                                    <FromFieldText
                                        star="*"
                                        label="Destination City"
                                        inputValue={destinationCity}
                                        setInputValue={setDestinationCity}
                                        setErrorMsg={ErrDestinationCity}
                                    />
                                </div>
                                <div className='col-6'>
                                    <FromFieldText
                                        star="*"
                                        label="Destination Country"
                                        inputValue={destinationCountry}
                                        setInputValue={setDestinationCountry}
                                        setErrorMsg={ErrDestinationCountry}
                                    />
                                </div>

                            </div>

                            <div className='row mb-2'>
                                {gatePassType === "Returnable" && (
                                    <div className='col-6'>
                                        <FromFieldDate
                                            star="*"
                                            label="Expected Date of Return"
                                            inputValue={expectedDate}
                                            setInputValue={setExpectedDate}
                                            setErrorMsg={ErrExpectedDate}
                                        />
                                    </div>
                                )}
                                <div className='col-6'>
                                    <FromFieldTextArea
                                        star="*"
                                        label="Reason for Taking Out"
                                        inputValue={reasonTakingOut}
                                        setInputValue={setReasonTakingOut}
                                        setErrorMsg={ErrReasonTakingOut}
                                    />
                                </div>
                                <div className='col-6'></div>
                            </div>

                            <div className="row mt-4">
                                <div className='col-12'>
                                    <table className="table">
                                        <thead>
                                        <th>TAG No</th>
                                        <th>TAG Name</th>
                                        <th>Descriptions</th>
                                        <th>Qty</th>
                                        <th>Currency Type</th>
                                        <th>Value</th>
                                        <th>Invoice No</th>
                                        <th>Remarks</th>
                                        </thead>
                                        <tbody>
                                        {info && info.length > 0 ? (
                                            info.map((option, index) => (
                                                <tr key={index}>
                                                    <td>{option.tag}</td>
                                                    <td>{option.tag_name}</td>
                                                    <td>{option.asset_description}</td>
                                                    <td>{option.qty}</td>
                                                    <td>{option.invoice_currency}</td>
                                                    <td>{option.value_of_asset}</td>
                                                    <td>{option.invoice_no}</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name={`itemDescription[${index}]`}
                                                            placeholder="Enter Remark"
                                                            value={descriptions[index] || ''}  // Ensure descriptions is an array
                                                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                                        />
                                                        {ErrDescription[index] && (
                                                            <span
                                                                className="error-message">{ErrDescription[index]}</span>  // Ensure ErrDescription[index] is a single string
                                                        )}
                                                    </td>

                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8">No Data</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>


                            </div>


                        </div>
                    </div>

                    <div className='row'>

                        <div className="col-md-3">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-primary btn-md float-end"> Back</button>
                            </form>
                        </div>

                        <div className='col-3'>
                            <form name="" onSubmit={handleSubmit1}>
                                <button className="btn btn-primary btn-md float-end"> Submit
                                </button>
                            </form>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>


            </div>


        </div>

    )
}

export default GatePass
    

