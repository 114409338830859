import React, {useEffect, useRef, useState} from 'react';
import {getGatePassDetailByGatePassNo, updateGatePass, updateGatePassRemark} from '../../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from '../../atoms/FromFieldTextArea';
import {getLastGatePassNo} from '../../services/user.service';
import FromFieldText from "../../atoms/FromFieldText";
import FromFieldDate from "../../atoms/FromFieldDate";
import {useLocation} from "react-router-dom";

function UpdateGatePass() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOptions1, setListData1] = useState([]);
    const [info, setInfo] = useState([]);

    const [remarks, setRemarks] = useState({});

    const [foundAssets, setFoundAssets] = useState();

    const [textBoxValue, setTextBoxValue] = useState('');

    const [gatePassNo, setGatePassNo] = useState('');
    const [ErrGatePassNo, setErrGatePassNo] = useState(false);

    const [gatePassDate, setGatePassDate] = useState('');
    const [ErrGatePassDate, setErrGatePassDate] = useState(false);

    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

    const [catOfEmp, setCatOfEmp] = useState('');
    const [ErrCatOfEmp, setErrCatOfEmp] = useState(false);

    const [gatePassType, setGatePassType] = useState('');
    const [ErrGatePassType, setErrGatePassType] = useState(false);

    const [employeeID, setEmployeeID] = useState('');
    const [ErrEmployeeID, setErrEmployeeID] = useState(false);

    const [costCenter, setCostCenter] = useState('');
    const [ErrCostCenter, setErrCostCenter] = useState(false);

    const [titles, setTitles] = useState('');
    const [ErrTitles, setErrTitles] = useState(false);

    const [designation, setDesignation] = useState('');
    const [ErrDesignation, setErrDesignation] = useState(false);

    const [vendorName, setVendorName] = useState('');
    const [ErrVendorName, setErrVendorName] = useState(false);

    const [address, setAddress] = useState('');
    const [ErrAddress, setErrAddress] = useState(false);

    const [destinationCity, setDestinationCity] = useState('');
    const [ErrDestinationCity, setErrDestinationCity] = useState(false);

    const [destinationCountry, setDestinationCountry] = useState('');
    const [ErrDestinationCountry, setErrDestinationCountry] = useState(false);

    const [expectedDate, setExpectedDate] = useState('');
    const [ErrExpectedDate, setErrExpectedDate] = useState(false);

    const [reasonTakingOut, setReasonTakingOut] = useState('');
    const [ErrReasonTakingOut, setErrReasonTakingOut] = useState(false);

    const [descriptions, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState([]);

    const [mInfo, setMInfo] = useState([]);

    const [material, setMaterials] = useState([{ slNo: 1, tagNo: '', tagName: '', description: '', qty: '', currencyType: '', value: '', invoiceNo: '', remarks: '' }]);

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (!TEXT_REGEX.test(catOfEmp)) {
            setErrCatOfEmp('Category is required');
            valid_Name = false;
        } else {
            setErrCatOfEmp('');
        }
        if (!TEXT_REGEX.test(gatePassType)) {
            setErrGatePassType('Gate Pass type is required');
            valid_Name = false;
        } else {
            setErrGatePassType('');
        }
        if (catOfEmp === 'Employee') {
            if (!TEXT_REGEX.test(employeeID)) {
                setErrEmployeeID('Employee Id is required');
                valid_Name = false;
            } else {
                setErrEmployeeID('');
            }
            if (!TEXT_REGEX.test(costCenter)) {
                setErrCostCenter('Cost Center is required');
                valid_Name = false;
            } else {
                setErrCostCenter('');
            }
        } else {
            setErrEmployeeID(''); // Clear any previous error if not 'Employee'
        }
        if (!TEXT_REGEX.test(titles)) {
            setErrTitles('Title is required');
            valid_Name = false;
        } else {
            setErrTitles('');
        }
        if (!TEXT_REGEX.test(designation)) {
            setErrDesignation('Title is required');
            valid_Name = false;
        } else {
            setErrDesignation('');
        }
        if (!TEXT_REGEX.test(gatePassNo)) {
            setErrGatePassNo('Gate Pass no is required');
            valid_Name = false;
        } else {
            setErrGatePassNo('');
        }
        if (!gatePassDate) {
            setErrGatePassDate('Gate Pass Date is required');
            valid_Name = false;
        } else {
            setErrGatePassDate('');
        }
        if (!TEXT_REGEX.test(vendorName)) {
            setErrVendorName('Vendor name is required');
            valid_Name = false;
        } else {
            setErrVendorName('');
        }
        if (!TEXT_REGEX.test(address)) {
            setErrAddress('Material taken to (Address) is required');
            valid_Name = false;
        } else {
            setErrAddress('');
        }
        if (!TEXT_REGEX.test(destinationCity)) {
            setErrDestinationCity('Destination city is required');
            valid_Name = false;
        } else {
            setErrDestinationCity('');
        }
        if (!TEXT_REGEX.test(destinationCountry)) {
            setErrDestinationCountry('Destination country is required');
            valid_Name = false;
        } else {
            setErrDestinationCountry('');
        }
        if (!expectedDate) {
            setErrExpectedDate('Expected date is required');
            valid_Name = false;
        } else {
            setErrExpectedDate('');
        }
        if (!TEXT_REGEX.test(reasonTakingOut)) {
            setErrReasonTakingOut('Reason taking out is required');
            valid_Name = false;
        } else {
            setErrReasonTakingOut('');
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            saveData();
            //handleNextClick();
        }
    }

    const resetUserInfo = () => {
        setCatOfEmp('');
        setGatePassType('');
        setEmployeeID('');
        setCostCenter('');
        setTitles('');
        setDesignation('');
        setDescription('');
        setGatePassNo('');
        setGatePassDate('');
        setVendorName('');
        setAddress('');
        setDestinationCity('');
        setDestinationCountry('');
        setExpectedDate('');
        setReasonTakingOut('');

    };
    const saveData = () => {
        try {

            var obj = {
                'catOfEmp': catOfEmp,
                'gatePassType': gatePassType,
                'employeeID': employeeID,
                'costCenter': costCenter,
                'titles': titles,
                'designation': designation,
                'gatePassNo': gatePassNo,
                'gatePassDate': gatePassDate,
                'vendorName': vendorName,
                'address': address,
                'destinationCity': destinationCity,
                'destinationCountry': destinationCountry,
                'expectedDate': expectedDate,
                'reasonTakingOut': reasonTakingOut,
                //'existing_ownership_id': sessionStorage.getItem('LoggedInDepartmentId'),
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                //'status':'4'
            };

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            updateGatePass(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            var obj1 = {

                'gatePassNo': gatePassNo,
                'remark': descriptions,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};
            updateGatePassRemark(oData1).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const gatePassNos = query.get('gatePassNo');
    const elementRef = useRef();

    useEffect(() => {
        if (gatePassNos !== '' && gatePassNos !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(gatePassNos.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedGatePassNo = decrypt_data.gatePassNo;
            getTransferInfo(decryptedGatePassNo);
        }
    }, []);

    const getTransferInfo = function (decryptedGatePassNo) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'gatePassNo': decryptedGatePassNo },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = { 'token': EncryptData };
        getGatePassDetailByGatePassNo(oData1).then(function (res) {
            if (res?.data?.data) {
                var aInfo = res.data.data.gate_pass_data;
                setMInfo(res.data.data.tag_details);

                setGatePassNo(decryptedGatePassNo);
                setCatOfEmp(aInfo.catOfEmp);
                setGatePassType(aInfo.gatePassType);
                setEmployeeID(aInfo.employeeID);
                setCostCenter(aInfo.costCenter);
                setTitles(aInfo.titles);
                setDesignation(aInfo.designation);
                setVendorName(aInfo.vendorName);
                setAddress(aInfo.address);
                setExpectedDate(new Date(aInfo.expectedDate));
                setGatePassDate(new Date(aInfo.gatePassDate));
                setDestinationCity(aInfo.destinationCity);
                setDestinationCountry(aInfo.destinationCountry);
                setReasonTakingOut(aInfo.reasonTakingOut);
                setInfo(aInfo);
            }
        });
    };

    useEffect(() => {
        if (mInfo.length > 0) {
            const mappedMaterials = mInfo.map(material => ({
                tag: material.tag,
                tag_name: material.tag_name,
                asset_description: material.asset_description,
                qty: material.qty,
                invoice_currency: material.invoice_currency,
                invoice_no: material.invoice_no,
                value_of_asset: material.value_of_asset,
                remark: material.remark
            }));

            setMaterials(mappedMaterials);
        }
    }, [mInfo]);

    const handleMaterialChange = (index, event) => {
        const { name, value } = event.target;
        const updatedMaterials = material.map((material, i) => (
            i === index ? { ...material, [name]: value } : material
        ));
        setMaterials(updatedMaterials);
    };

    const handleCATofEmp = (event) => {
        setCatOfEmp(event.target.value);  // Update the state with the selected value
    };

    const handleGatePassType = (event) => {
        setGatePassType(event.target.value);  // Update the state with the selected value

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        // After updating GatePassType, call getLastGatePassNo and update GatePassNo
        getLastGatePassNo(oData).then(res => {

            let lastDinId = ((res.data.total) ? res.data.total : 0) + 1;
            let newlastDinId = lastDinId.toString().padStart(4, '0');
            let currentYear = new Date().getFullYear();
            let divisionCode = sessionStorage.LoggedInDivisionCode;

            // Logic to add 'ER' or 'VR' based on CATofEmp and GatePassType
            let prefix = '';

            if (catOfEmp === 'Employee') {
                if (event.target.value === 'Returnable') {
                    prefix = 'ER';  // Employee and Returnable
                } else if (event.target.value === 'Non Returnable') {
                    prefix = 'ENR';  // Employee and Non-Returnable
                }
            } else if (catOfEmp === 'Vendor') {
                if (event.target.value === 'Returnable') {
                    prefix = 'VR';  // Vendor and Returnable
                } else if (event.target.value === 'Non Returnable') {
                    prefix = 'VNR';  // Vendor and Non-Returnable
                }
            }

            setGatePassNo('GP' + divisionCode + currentYear + prefix + newlastDinId);


            setGatePassNo('GP' + divisionCode + currentYear + prefix + newlastDinId);
        });
    };

    const handleDescriptionChange = (index, value) => {
        const newDescriptions = [...descriptions];
        newDescriptions[index] = value;
        setDescription(newDescriptions);
    };

    return (
        <div className='container'>

            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}
            <div className="title">
                <h5>Update Gate Pass</h5>

            </div>
            <div>
                <div className="title">
                <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'>
                            <a href={'/view-gate-pass?gatePassNo=' + gatePassNos}>
                            <button className='btn btn-secondary'><i className="bi bi-arrow-left"></i> Back</button>
                            </a>
                            
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-10'>&nbsp;</div>
                    </div>
                    <div className='row'>
                        <div className="col-6">
                            <div className="row ">
                                <div className="col-6">
                                    <label>CAT <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select onChange={handleCATofEmp}
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Employee"
                                                selected={catOfEmp === "Employee" ? "selected" : ''}>Employee
                                        </option>
                                        <option value="Vendor"
                                                selected={catOfEmp === "Vendor" ? "selected" : ''}>Vendor
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrCatOfEmp}</span>
                                </div>
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="row ">
                                <div className="col-6">
                                    <label>Gate Pass Type <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select onChange={handleGatePassType}
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Returnable"
                                                selected={gatePassType === "Returnable" ? "selected" : ''}>Returnable
                                        </option>
                                        <option value="Non Returnable"
                                                selected={gatePassType === "Non Returnable" ? "selected" : ''}>Non
                                            Returnable
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrGatePassType}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Conditional rendering based on the selected CAT */}
                    {catOfEmp === "Employee" && (
                        <div className='row'>
                            <div className="col-6">
                                <FromFieldText
                                    star="*"
                                    label="Employee ID"
                                    inputValue={employeeID}
                                    setInputValue={setEmployeeID}
                                    setErrorMsg={ErrEmployeeID}
                                />
                            </div>
                            <div className='col-6'>
                                <FromFieldText
                                    star="*"
                                    label="Cost Centre"
                                    inputValue={costCenter}
                                    setInputValue={setCostCenter}
                                    setErrorMsg={ErrCostCenter}
                                />
                            </div>
                        </div>
                    )}


                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                star="*"
                                label="Smt / Sri"
                                inputValue={titles}
                                setInputValue={setTitles}
                                setErrorMsg={ErrTitles}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Designation"
                                inputValue={designation}
                                setInputValue={setDesignation}
                                setErrorMsg={ErrDesignation}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                readOnly
                                star="*"
                                label="Gate Pass Number"
                                inputValue={gatePassNo}
                                setInputValue={setGatePassNo}
                                setErrorMsg={ErrGatePassNo}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldDate
                                star="*"
                                label="Gate Pass Date"
                                inputValue={gatePassDate}
                                setInputValue={setGatePassDate}
                                setErrorMsg={ErrGatePassDate}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                star="*"
                                label="Vendor Company Name"
                                inputValue={vendorName}
                                setInputValue={setVendorName}
                                setErrorMsg={ErrVendorName}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Material taken to (Address)"
                                inputValue={address}
                                setInputValue={setAddress}
                                setErrorMsg={ErrAddress}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                star="*"
                                label="Destination City"
                                inputValue={destinationCity}
                                setInputValue={setDestinationCity}
                                setErrorMsg={ErrDestinationCity}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Destination Country"
                                inputValue={destinationCountry}
                                setInputValue={setDestinationCountry}
                                setErrorMsg={ErrDestinationCountry}
                            />
                        </div>

                    </div>

                    <div className='row mb-2'>
                        <div className='col-6'>
                            <FromFieldDate
                                star="*"
                                label="Expected Date of Return"
                                inputValue={expectedDate}
                                setInputValue={setExpectedDate}
                                setErrorMsg={ErrExpectedDate}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldTextArea
                                star="*"
                                label="Reason for Taking Out"
                                inputValue={reasonTakingOut}
                                setInputValue={setReasonTakingOut}
                                setErrorMsg={ErrReasonTakingOut}
                            />
                        </div>
                        <div className='col-6'></div>
                    </div>

                    <div className="row mt-4">
                        <div className='col-12'>
                            <table border="1" width="100%"
                                   style={{borderCollapse: 'separate', borderSpacing: '10px', textAlign: 'center'}}>
                                <thead>
                                <tr>
                                    <th>Sl No</th>
                                    <th>TAG No</th>
                                    <th>TAG Name</th>
                                    <th>Descriptions</th>
                                    <th>Qty</th>
                                    <th>Currency Type</th>
                                    <th>Value</th>
                                    <th>Invoice No</th>
                                    <th>Remarks</th>
                                </tr>
                                </thead>
                                <tbody>
                                {material.map((material, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td> {/* Dynamically generate serial number */}
                                        <td>{material.tag}</td>
                                        <td>{material.tag_name}</td>
                                        <td>{material.asset_description}</td>
                                        <td>{material.qty}</td>
                                        <td>{material.invoice_currency}</td>
                                        <td>{material.value_of_asset}</td>
                                        <td>{material.invoice_no}</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="remark"
                                                value={material.remark}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                            />
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>


                    </div>


                </div>
            </div>

            <div className='row mt-4'>

                <div className='col-6'>
                    <form name="" onSubmit={handleSubmit1}>
                        <button className="btn btn-primary btn-md float-end"> Update
                        </button>
                    </form>
                </div>
                <div className="col-md-6">

                </div>
            </div>

        </div>

    )
}

export default UpdateGatePass
    

