import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getInvoiceCurrencyList, deleteInvoiceCurrency} from '../../services/master.service'
import * as CryptoJS from "crypto-js";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);

function InvoiceCurrencyList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    /*var onCellEditStopFn = (event,newCell) => {
      var obj = {};
      obj[newCell.field] = event.target.value;
    }*/

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const columns = [
        {
            "field": "currency_type_name",
            "editable": false,
            "hide": false,
            "headerName": "Invoice Currency Name",
            "width": 350
        },
        {"field": "company_name", "editable": false, "hide": false, "headerName": "Company Name", "width": 350},
        {"field": "company_details", "editable": false, "hide": true, "headerName": "Category Details", "width": 350},
        {
            "field": "created_at", "editable": true, "hide": false, "headerName": "Created Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
        {

            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'ic_id': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span>
                    <a href={`/addinvoicecurrency/?ic_id=${EncryptID}`} className="p-2"><button type="button" className="btn btn-primary btn-md">
          <i className="bi bi-pencil"></i></button></a>

                <button
                    type="button"
                    className="btn btn-danger btn-sm ml-2"
                    onClick={() => handleDeleteClick(params.row.id)}
                >
                            <i className="bi bi-trash"></i>
                        </button>

                </span>
                );
            }
        }
    ];
    const handleDeleteClick = (costId) => {
        //alert(costId);
        //console.log(`Deleting cost center with ID: ${costId}`);


        const confirmDelete = window.confirm("Are you sure you want to delete this cost center?");

        if (confirmDelete) {

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'invoiceCurrency_id': costId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteInvoiceCurrency(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }



    };

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                //'param_data':obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { 'token': EncryptData };

        setLoading(true); // Set loading to true when starting the request

        getInvoiceCurrencyList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false regardless of success or failure
            });
    }, []);

    // readCategory();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Invoice Currency List</h5></div>

            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}
        </div>
    )
}

export default InvoiceCurrencyList
